import React from 'react';
import {Box} from '@mui/material';
import {Speedometer} from 'mui-core';
import {resumeStrengthText} from 'data/resume.json';

const customLabelPlacementValues = {
  okPlacement: {
    lg: 24,
    md: 32,
    sm: 40,
    xs: 32,
  },
  goodPlacment: {
    lg: 34,
    md: 20,
    sm: 0,
    xs: 10,
  },
  greatPlacement: {
    lg: 5,
    md: 12,
    sm: 16,
    xs: 15,
  },
};

const labelSizeValues = {
  xs: 10,
  sm: 13,
  md: 12,
  lg: 13,
};

const ResumeStrength = ({resumeStrengthValue, tooltipData}) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Speedometer
        width={280}
        size='small'
        showBadge={true}
        showGradient={true}
        showDialValue={false}
        badgeData={tooltipData}
        tooltipWidth={824}
        labelSize={labelSizeValues}
        dialValue={resumeStrengthValue}
        badgeLabel={resumeStrengthText}
        customLabelPlacement={customLabelPlacementValues}
      />
    </Box>
  );
};

export default ResumeStrength;
