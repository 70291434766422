import {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useUser, useEmailTrigger} from 'core/hooks';
// components
import {
  Grid,
  Button,
  MUIInput,
  DialogBox,
  ContentBox,
  ErrorBoundary,
} from 'mui-core';
import {
  Stack,
  Divider,
  TextField,
  Typography,
  dialogClasses,
  FormHelperText,
} from '@mui/material';
import {createField} from 'mui-core/Form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// redux
import {doLogout} from 'redux/modules/auth/actions';
import {changeEmail, updatePassword} from 'redux/modules/profile';
// utils
import {PATTERN} from 'core/regex';
import {emailSuccess} from 'assets/images';
import {styled} from '@mui/material/styles';
import {accountAccess} from 'data/dashboard-settings.json';

const {
  okTxt,
  heading,
  errorMsg,
  cancelTxt,
  confirmTxt,
  reLoginTxt,
  successMsg,
  subHeading,
  newPasswordTxt,
  changeEmailTxt,
  emailRequiredTxt,
  changePasswordTxt,
  currentPasswordTxt,
  enterNewEmailLabel,
  confirmPasswordTxt,
  emailFormatErrorTxt,
  passwordMinLengthTxt,
  emailChangeSuccessTxt,
  passwordDidNotMatchTxt,
  newPasswordRequiredTxt,
  enterNewEmailPlaceholder,
  changePasswordSubHeading,
  currentPasswordRequiredTxt,
  confirmPasswordRequiredTxt,
  currentPasswordDidNotMatchTxt,
} = accountAccess || {};

const StyledButton = styled(Button)(({theme, emailVerified}) => ({
  minWidth: 120,
  fontSize: '14px',
  boxShadow: 'none',
  padding: emailVerified ? '6px' : '6px 20px',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: emailVerified
      ? theme.palette.success.main
      : theme.palette.primary.main,
    cursor: emailVerified ? 'default' : 'pointer',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '11px',
  },
}));

const customDialogBox = {
  [`& .${dialogClasses.paper}`]: {
    width: 500,
  },
};

const AccountAccess = () => {
  const form = useForm();
  const dispatch = useDispatch();
  const {data: userData} = useUser();
  const {enqueueSnackbar} = useSnackbar();
  const {onEmailVerify} = useEmailTrigger();
  const {reset, setError, handleSubmit} = form;
  const [verifyRequest, setVerifyRequest] = useState(false);
  const [changeEmailLoader, setChangeEmailLoader] = useState(false);
  const [changeEmailDialog, setChangeEmailDialog] = useState(false);
  const [changeEmailSuccessDialog, setChangeEmailSuccessDialog] = useState(
    false,
  );
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const {email = '', student_uuid, social_account, email_verified = false} =
    userData || {};

  const onVerifyEmail = async () => {
    setVerifyRequest(true);
    try {
      const res = await onEmailVerify();
      if (res?.Error) {
        enqueueSnackbar(res.Error ? res.Error : errorMsg, {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
      if (res?.Success) {
        enqueueSnackbar(`${successMsg} ${email}`, {
          variant: 'success',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    } catch (err) {
      setVerifyRequest(false);
    }
    setVerifyRequest(false);
  };

  const handleChangePassword = values => {
    const updatedValues = {...values};
    if (updatedValues?.new_password === updatedValues?.confirm_password) {
      delete updatedValues.confirm_password;
      dispatch(
        updatePassword(student_uuid, updatedValues, res => {
          if (res.Error) {
            setError('old_password', {
              type: 'custom',
            });
            enqueueSnackbar(currentPasswordDidNotMatchTxt, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          } else {
            handlecloseDialog();
            enqueueSnackbar(res.Success, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
        }),
      );
    } else {
      setError('confirm_password', {
        type: 'custom',
      });
    }
  };

  const handlecloseDialog = () => {
    reset();
    setOpenChangePasswordModal(false);
  };

  const openChangeEmailDialog = () => {
    setChangeEmailDialog(true);
    form.reset();
  };

  const closeEmailSuccessDialog = () => {
    setChangeEmailSuccessDialog(false);
    dispatch(doLogout(true));
  };

  const submitChangeEmail = values => {
    const data = {
      ...values,
      student_uuid: student_uuid,
      new_email: values.new_email.toLowerCase(),
    };
    setChangeEmailLoader(true);
    dispatch(
      changeEmail(data, res => {
        if (res.Success) {
          setChangeEmailDialog(false);
          setChangeEmailSuccessDialog(true);
        } else {
          enqueueSnackbar(res.Error || res.error || {errorMsg}, {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'top'},
          });
        }
        setChangeEmailLoader(false);
      }),
    );
  };

  return (
    <ContentBox elevation={0} sx={{p: 4}}>
      <ErrorBoundary nameOfComponent='dashboard-account-access'>
        <Typography variant='h4' color='text.primary'>
          {heading}
        </Typography>
        <Typography
          my={2}
          fontWeight={500}
          variant='subtitle2'
          color='text.primary'>
          {subHeading}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              gap={2}
              alignItems='flex-start'
              justifyContent='flex-start'
              direction={{xs: 'column', sm: 'row'}}>
              <TextField
                disabled
                fullWidth
                size='small'
                value={email}
                variant='outlined'
                sx={{maxWidth: 400}}
                label='Email Address'
                id='current-email-address'
              />
              <StyledButton
                size='large'
                loading={verifyRequest}
                emailVerified={email_verified}
                disableRipple={email_verified}
                color={email_verified ? 'success' : 'primary'}
                startIcon={
                  email_verified && (
                    <CheckCircleIcon
                      sx={{
                        fontSize: {
                          xs: 'small !important',
                          sm: 'large !important',
                        },
                      }}
                    />
                  )
                }
                variant={email_verified ? 'contained' : 'outlined'}
                onClick={!email_verified ? onVerifyEmail : () => {}}>
                {email_verified ? 'Verified' : 'Verify Email'}
              </StyledButton>
              {social_account !== 'google' && (
                <>
                  <StyledButton
                    size='large'
                    loading={false}
                    emailVerified={false}
                    disableRipple={false}
                    color={'primary'}
                    variant={'outlined'}
                    onClick={openChangeEmailDialog}>
                    {changeEmailTxt}
                  </StyledButton>
                  <StyledButton
                    size='large'
                    variant='outlined'
                    onClick={() => setOpenChangePasswordModal(true)}>
                    {changePasswordTxt}
                  </StyledButton>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
        <DialogBox
          disableIcon
          showDivider
          customizeable
          fullScreen
          closeDialog={handlecloseDialog}
          customDialogsx={customDialogBox}
          openDialog={openChangePasswordModal}>
          <form onSubmit={handleSubmit(handleChangePassword)}>
            <Stack spacing={2}>
              <Typography variant='h3' color='text.primary'>
                {changePasswordTxt}
              </Typography>
              <Divider />
              <FormHelperText variant='h4'>
                {changePasswordSubHeading}
              </FormHelperText>
              <Stack px={{xs: 0, md: 4}} gap={5}>
                {createField('old_password', {
                  form,
                  label: currentPasswordTxt,
                  rules: {
                    minLength: [8, passwordMinLengthTxt],
                    required: [true, currentPasswordRequiredTxt],
                    custom: [true, currentPasswordDidNotMatchTxt],
                  },
                })(
                  <MUIInput
                    variant='outlined'
                    typeOfInput='password'
                    id={currentPasswordTxt}
                    label={currentPasswordTxt}
                    ariaLabel={currentPasswordTxt}
                    placeholder={currentPasswordTxt}
                  />,
                )}
                {createField('new_password', {
                  form,
                  label: newPasswordTxt,
                  rules: {
                    minLength: [8, passwordMinLengthTxt],
                    required: [true, newPasswordRequiredTxt],
                  },
                })(
                  <MUIInput
                    variant='outlined'
                    id={newPasswordTxt}
                    typeOfInput='password'
                    label={newPasswordTxt}
                    ariaLabel={newPasswordTxt}
                    placeholder={newPasswordTxt}
                  />,
                )}
                {createField('confirm_password', {
                  form,
                  label: confirmPasswordTxt,
                  rules: {
                    minLength: [8, passwordMinLengthTxt],
                    required: [true, confirmPasswordRequiredTxt],
                    custom: [true, passwordDidNotMatchTxt],
                  },
                })(
                  <MUIInput
                    variant='outlined'
                    typeOfInput='password'
                    id={confirmPasswordTxt}
                    label={confirmPasswordTxt}
                    ariaLabel={confirmPasswordTxt}
                    placeholder={confirmPasswordTxt}
                  />,
                )}
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                  <Button variant='outlined' onClick={handlecloseDialog}>
                    {cancelTxt}
                  </Button>
                  <Button formNoValidate type='submit' variant='contained'>
                    {changePasswordTxt}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </DialogBox>
        <DialogBox
          closeIcon
          showDivider
          customizeable
          showIcon={false}
          title={changeEmailTxt}
          openDialog={changeEmailDialog}>
          <form>
            <Stack spacing={4} direction='column'>
              {createField('new_email', {
                form,
                defaultValue: '',
                label: enterNewEmailLabel,
                disabled: changeEmailLoader,
                rules: {
                  required: [true, emailRequiredTxt || ''],
                  pattern: [PATTERN?.Email, emailFormatErrorTxt],
                },
              })(
                <MUIInput
                  id='new_email'
                  variant='outlined'
                  typeOfInput='email'
                  label={enterNewEmailLabel}
                  ariaLabel={newPasswordTxt}
                  placeholder={enterNewEmailPlaceholder}
                />,
              )}
              {createField('password', {
                form,
                defaultValue: '',
                label: currentPasswordTxt,
                disabled: changeEmailLoader,
                rules: {
                  minLength: [8, passwordMinLengthTxt],
                  required: [true, currentPasswordRequiredTxt],
                },
              })(
                <MUIInput
                  id='new_email'
                  variant='outlined'
                  typeOfInput='password'
                  label={currentPasswordTxt}
                  ariaLabel={currentPasswordTxt}
                  placeholder={currentPasswordTxt}
                />,
              )}
              <Stack spacing={2} direction='row'>
                <Button
                  variant='outlined'
                  loading={changeEmailLoader}
                  onClick={() => setChangeEmailDialog(false)}
                  sx={{minWidth: {xs: '80px', md: '120px'}}}>
                  {cancelTxt}
                </Button>
                <Button
                  variant='contained'
                  loading={changeEmailLoader}
                  sx={{minWidth: {xs: '80px', md: '120px'}}}
                  onClick={handleSubmit(submitChangeEmail)}>
                  {confirmTxt}
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogBox>
        {changeEmailSuccessDialog && (
          <DialogBox
            size='xs'
            closeIcon
            showIcon={emailSuccess}
            openDialog={changeEmailSuccessDialog}>
            <Stack mb={2} spacing={1} alignItems='center'>
              <Typography textAlign='center' variant='h4' color='text.primary'>
                {emailChangeSuccessTxt}
              </Typography>
              <Typography
                variant='body1'
                textAlign='center'
                color='text.primary'>
                {reLoginTxt}
              </Typography>
              <Button
                variant='contained'
                onClick={closeEmailSuccessDialog}
                sx={{width: '100px', marginTop: '16px !important'}}>
                {okTxt}
              </Button>
            </Stack>
          </DialogBox>
        )}
      </ErrorBoundary>
    </ContentBox>
  );
};

export default AccountAccess;
