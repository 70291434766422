import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {numberInUSFormat} from 'core/utils';
import {useHistory} from 'react-router-dom';
import {MoreHoriz} from '@mui/icons-material';
import {defaultBannerImage} from 'assets/images';
import {styled, useTheme} from '@mui/material/styles';
import {Card, Menu, LazyImage, ErrorBoundary} from 'mui-core';
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Typography,
  CardActionArea,
} from '@mui/material';

const EllipsesIcon = styled(IconButton)(({theme}) => ({
  zIndex: 1,
  top: '0px',
  right: '12px',
  float: 'right',
  padding: '2px',
  marginTop: '12px',
  marginRight: '10px',
  position: 'absolute',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));
const StyledTypography = styled(Typography)(({}) => ({
  width: '90%',
  height: '52px',
  overflow: 'hidden',
  color: 'text.primary',
  display: '-webkit-box',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
}));

const TagContainer = styled(Stack)(({}) => ({
  top: 24,
  left: 0,
  zIndex: 1,
  position: 'absolute',
}));

const tagTypeColor = (theme, tagType) => {
  switch (tagType) {
    case 'in_demand': {
      return {background: theme.palette.teal.main, color: 'white'};
    }
    default:
      return {};
  }
};
const Tag = styled(Box)(({theme, type}) => ({
  padding: '4px 24px',
  borderRadius: '0 12px 12px 0',
  ...tagTypeColor(theme, type),
}));

const CareerCard = ({
  data,
  width,
  infoItems,
  displayEllipses,
  enableNavigation,
  target = '_blank',
  imageHeight = 172,
}) => {
  const {
    uuid,
    title,
    cip_code,
    video_code,
    video_details,
    occupation_title,
    banner_cloudinary,
    in_demand_tag = false,
    occupation_details: {occupation_name} = {},
  } = data || {};
  const theme = useTheme();
  const history = useHistory();
  const cardWidth = width ? {width} : {};
  const [anchorEl, setAnchorEl] = useState(null);
  const occupationName = title || occupation_title || occupation_name;
  const open = Boolean(anchorEl);
  const {
    location: {pathname},
  } = history;

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const metricsTextColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey.main
      : theme.palette.grey.contrastText;

  const formatCount = count => {
    if (count === '' || count === undefined || count === null) return '0';
    if (typeof count === 'string') {
      const regex = /^\d+$/;
      if (regex.test(count)) {
        return count >= 10000
          ? '10,000+'
          : numberInUSFormat(parseInt(count) || 0);
      } else {
        return count || 0;
      }
    } else if (typeof count === 'number' && count >= 10000) {
      return '10,000+';
    } else {
      return numberInUSFormat(parseInt(count) || 0);
    }
  };

  const renderInfoItem = infoItem => {
    const {Icon, count, label = ''} = infoItem || {};
    return (
      ((label && count > 0) ||
        (label === 'Salary' && (count === null || count === 0))) && (
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <Icon
            sx={{
              width: 18,
              height: 18,
              marginBottom: '2px',
              color: metricsTextColor,
            }}
          />
          <Typography
            variant='h7'
            fontSize={13}
            color={theme.palette.teal.main}>
            {label === 'Salary' &&
            (count === null || count === 0 || count === '')
              ? 'N/A'
              : label === 'Salary'
              ? numberInUSFormat(parseInt(count))
              : formatCount(count)}
          </Typography>
          <Typography
            variant='h7'
            fontSize={12}
            color={metricsTextColor}
            textTransform='uppercase'>
            {label === 'Salary' ? 'Median Salary' : label}
          </Typography>
        </Stack>
      )
    );
  };

  const onNavigation = () => {
    let url = '';
    if (occupationName && cip_code) {
      url = `/career-details/${encodeURIComponent(occupationName)}/${cip_code}`;
      target === '_self' || pathname === '/' || pathname.includes('/dashboard')
        ? history.push(url)
        : window.open(url, '_blank').focus();
    }
  };

  const getMenu = () => {
    let menu = [];
    let dataPassed = {};
    if (displayEllipses && displayEllipses.length > 0) {
      displayEllipses.map(item => {
        if ('passingData' in item) {
          item.passingData.map(param => {
            dataPassed[param] = eval(param);
          });
          item['dataPassed'] = dataPassed || data;
        }
      });
      menu = displayEllipses;
    }
    return (
      <Menu data={menu} open={open} anchorEl={anchorEl} onClose={handleClose} />
    );
  };

  let imgURL = '';
  if (video_code) {
    imgURL = `https://cdn.careeronestop.org/OccVids/OccupationVideos/${video_code}.jpg`;
  }
  if (video_details?.[0]?.VideoCode) {
    imgURL = `https://cdn.careeronestop.org/OccVids/OccupationVideos/${video_details?.[0]?.VideoCode}.jpg`;
  }

  return (
    <ErrorBoundary nameOfComponent='explore-career-card'>
      <Card
        sx={{
          ...cardWidth,
          cursor: 'pointer',
          textAlign: 'left',
          position: 'relative',
          margin: '0px auto 16px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}
        component={CardActionArea}
        onClick={() => enableNavigation && onNavigation()}>
        <TagContainer spacing={1}>
          {in_demand_tag && (
            <Tag alignItems='center' type='in_demand'>
              <Typography variant='body2'>In-Demand</Typography>
            </Tag>
          )}
        </TagContainer>
        {displayEllipses && (
          <>
            <EllipsesIcon
              size='small'
              id='basic-button'
              component='label'
              aria-haspopup='true'
              onClick={handleClick}
              aria-expanded={open ? 'true' : undefined}
              aria-controls={open ? 'basic-menu' : undefined}>
              <MoreHoriz />
            </EllipsesIcon>
            {getMenu()}
          </>
        )}
        <Box height={imageHeight}>
          {/* Banner Cloudinary Image */}
          {banner_cloudinary && (
            <LazyImage
              renderSrcSet
              src={banner_cloudinary}
              alt={`${occupationName?.slice(0, 50) || ''}-banner-image`}
            />
          )}
          {/* Video Coded Image */}
          {!banner_cloudinary && imgURL && (
            <LazyImage
              src={imgURL}
              alt={`${occupationName?.slice(0, 50) || ''}-occupation-img`}
            />
          )}
          {/* Default Banner Image */}
          {!banner_cloudinary && !imgURL && (
            <LazyImage
              src={defaultBannerImage}
              alt={`${occupationName?.slice(0, 50) || ''}-default-img`}
            />
          )}
        </Box>
        <Stack m='20px'>
          {occupationName && (
            <StyledTypography
              variant='h6'
              textTransform='capitalize'
              title={occupationName || ''}>
              {occupationName}
            </StyledTypography>
          )}
          {infoItems && (
            <Stack>
              <Divider sx={{mb: '20px', mt: 1}} />
              <Stack
                rowGap={1}
                columnGap={2}
                minHeight={48}
                flexWrap='wrap'
                direction='row'
                alignItems='flex-start'
                justifyContent='flex-start'>
                {infoItems &&
                  Array.isArray(infoItems) &&
                  infoItems?.length > 0 &&
                  infoItems.map(items => renderInfoItem(items))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Card>
    </ErrorBoundary>
  );
};

CareerCard.propTypes = {
  width: PropTypes.number,
  enableNavigation: PropTypes.bool,
  data: PropTypes.object.isRequired,
  displayEllipses: PropTypes.bool || PropTypes.array,
};

export default CareerCard;
