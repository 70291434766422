import React, {useState, useEffect} from 'react';
import {useUser} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {debounce, isString} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {Button, DialogBox, AutoComplete, RequestErrorLoader} from 'mui-core';
import {
  Box,
  Stack,
  Select,
  MenuItem,
  useTheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
// Redux
import {
  getUserTypeList,
  fetchUserTypelist,
  updateUserProfile,
} from 'redux/modules/profile';
import {fetchStates, getStates} from 'redux/modules/general';
import {fetchLocation, getLocationSearch} from 'redux/modules/occupation';
import SettingsJSON from 'data/dashboard-settings.json';
import {spotlightBgImg} from 'assets/images';

const {
  updateProfileModal: {
    heading,
    errorMsg,
    buttonTxt,
    successMsg,
    description,
    userTypeLabel,
    locationLabel,
    userTypePlaceholder,
    requiredUserTypeMsg,
    requiredLocationMsg,
    locationPlaceHolder,
    locationDescription,
    userTypeDescription,
  },
} = SettingsJSON;

const UpdateProfile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [close, setClose] = useState(false);
  const [location, setLocation] = useState();
  const [userType, setUserType] = useState();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {data: userData, request: userDataRequest} = useUser();
  const {data: statesList = []} = useSelector(getStates) || {};
  const {LocationSearch: {data: locationData, request} = {}} =
    useSelector(getLocationSearch) || {};
  const {data: userTypeList = []} = useSelector(getUserTypeList) || {};
  const {location_details = []} = locationData || {};
  const {user_type, address} = userData || {};
  const [locationError, setLocationError] = useState(false);
  const [userTypeError, setUserTypeError] = useState(false);

  useEffect(() => {
    (!userTypeList ||
      (Array.isArray(userTypeList) && userTypeList?.length === 0)) &&
      dispatch(fetchUserTypelist());
    dispatch(fetchStates());
    dispatch(fetchLocation());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [city, stateCode] =
    isString(location) && location.includes(',') ? location.split(',') : [];

  const locationDetails =
    location_details &&
    location_details.length &&
    location_details?.find(item => item?.state_code == stateCode?.trim());

  const uuidDetails =
    statesList?.find(item => item.name == locationDetails?.state) || {};

  const onSearch = debounce((_, value, r) => {
    setLocationError(false);
    setLocation(value);
  }, 50);

  const onSubmit = () => {
    let data = {};
    let locErr = false;
    let userTypeErr = false;
    if (!user_type && userType) {
      data.user_type = userType;
    } else {
      if (!user_type) {
        userTypeErr = true;
        setUserTypeError(true);
      }
    }
    if (!address && location) {
      if (uuidDetails?.state_uuid) {
        data.address = {
          city: city,
          state: uuidDetails?.state_uuid,
        };
      } else {
        locErr = true;
        setLocationError(true);
      }
    } else {
      if (!address) {
        locErr = true;
        setLocationError(true);
      }
    }
    if (userTypeErr || locErr) {
      return;
    }
    if (data.user_type || data.address) {
      setLoading(true);
      dispatch(
        updateUserProfile(data, success => {
          setLoading(false);
          if (success) {
            setClose(true);
            enqueueSnackbar(successMsg, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          } else {
            enqueueSnackbar(errorMsg, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
        }),
      );
    }
  };

  return (
    <DialogBox
      size='md'
      fullScreen={isMobile}
      customizeable
      openDialog={!close}
      customDialogsx={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialog-paper': {
          borderRadius: '16px',
        },
      }}>
      <RequestErrorLoader
        hideEmpty
        fullScreen
        body={{request: userDataRequest}}
      />
      <Box sx={{position: 'relative', height: '100%'}}>
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${spotlightBgImg})`,
          }}>
          <Box
            sx={{
              top: 0,
              left: 0,
              zIndex: 1,
              width: '100%',
              opacity: 0.62,
              height: '100%',
              position: 'absolute',
              backgroundColor: theme.palette.Gray900.main,
            }}
          />
          <Stack
            sx={{
              zIndex: 2,
              height: '100%',
              padding: '64px 8px',
              alignItems: 'center',
              position: 'relative',
              justifyContent: 'center',
            }}
            spacing={1}
            direction='column'>
            <Typography
              component='h1'
              variant='header-md-medium'
              sx={{textAlign: 'center', color: 'white'}}>
              {heading}
            </Typography>
            <Typography
              component='p'
              variant='title-xs-thin'
              sx={{textAlign: 'center', color: 'white'}}
              dangerouslySetInnerHTML={{
                __html: user_type
                  ? locationDescription
                  : address
                  ? userTypeDescription
                  : description,
              }}
            />
            <Stack
              gap={3}
              sx={{
                width: {xs: 280, md: 530},
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiAutocomplete-root': {
                  borderRadius: '4px',
                  backgroundColor: 'white',
                },
                '& .MuiInputBase-root': {
                  width: '100%',
                  background: 'white',
                  fontFamily: 'Inter',
                  color: theme.palette.shadeyBlack.main,
                  '& svg': {
                    color: theme.palette.shadeyBlack.main,
                  },
                },
              }}>
              {!user_type && (
                <Box sx={{width: '100%', minHeight: '88px'}}>
                  <Typography color='white' variant='label-md' component='p'>
                    {userTypeLabel}
                  </Typography>
                  <Select
                    displayEmpty
                    id='user_type'
                    onChange={e => {
                      setUserTypeError(false);
                      setUserType(e.target.value);
                    }}
                    renderValue={selected => {
                      if (!selected) {
                        return (
                          <Typography variant='Label-md' component='p'>
                            {userTypePlaceholder}*
                          </Typography>
                        );
                      } else {
                        return userTypeList?.find(
                          item => item?.user_type_uuid === selected,
                        )?.user_type;
                      }
                    }}>
                    <MenuItem disabled value=''>
                      <Typography variant='Label-md' component='p'>
                        {userTypePlaceholder}
                      </Typography>
                    </MenuItem>

                    {Array.isArray(userTypeList) &&
                      userTypeList.map(item => (
                        <MenuItem
                          key={item.user_type_uuid}
                          value={item.user_type_uuid}>
                          {item.user_type}
                        </MenuItem>
                      ))}
                  </Select>
                  {userTypeError && (
                    <Typography
                      color='error'
                      textAlign='left'
                      variant='text-xs-regular'>
                      {requiredUserTypeMsg}
                    </Typography>
                  )}
                </Box>
              )}

              {!address && (
                <Box sx={{width: '100%', minHeight: '88px'}}>
                  <Typography color='white' variant='label-md' component='p'>
                    {locationLabel}
                  </Typography>
                  <AutoComplete
                    required
                    autoComplete={true}
                    autoHighlight={true}
                    fieldName='location'
                    sx={{width: '100%'}}
                    value={location || ''}
                    onInputChange={onSearch}
                    placeholder={locationPlaceHolder}
                    options={
                      request
                        ? []
                        : Array.isArray(location_details) &&
                          location_details.length > 0 &&
                          location_details.map(item => {
                            return {
                              ...item,
                              key: item.city,
                              label: `${item.city}, ${item.state_code}`,
                              title: `${item.city}, ${item.state_code}`,
                            };
                          })
                    }
                  />
                  {locationError && (
                    <Typography
                      color='error'
                      textAlign='left'
                      variant='text-xs-regular'>
                      {requiredLocationMsg}
                    </Typography>
                  )}
                </Box>
              )}
              <Box textAlign='center'>
                <Button
                  size={isMobile ? 'medium' : 'large'}
                  loading={loading}
                  variant='contained'
                  onClick={onSubmit}
                  type='submit'>
                  {buttonTxt}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </DialogBox>
  );
};

export default UpdateProfile;
