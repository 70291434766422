import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Grid,
  Tooltip,
  ContentBox,
  ErrorBoundary,
  RequestErrorLoader,
} from 'mui-core';
import moment from 'moment';
import {useUser} from 'core/hooks';
import {useSnackbar} from 'notistack';
import {Box, Stack, Switch, Typography, FormControlLabel} from '@mui/material';
import {
  getPrivacySetting,
  fetchPrivacySetting,
  updatePrivacySetting,
} from 'redux/modules/profile';
import {dataPrivacy} from 'data/dashboard-settings.json';

const {
  heading,
  errorMsg,
  subHeading,
  shareLabel,
  successMsg,
  toolTipNote,
  dataPrivacyItems,
} = dataPrivacy || {};

const DataPrivacy = () => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const privacy = useSelector(getPrivacySetting) || {};
  const {data: userData, request: userDataRequest} = useUser();
  const {dob = '', under_13_years_old = false} = userData || {};
  const {
    data: privacyData,
    request: privacyRequest,
    saverequest: privacySaveRequest,
  } = privacy || {};
  const {privacy_uuid: privacyUUID = ''} = privacyData || {};

  // date of birth
  const DOB = moment(dob).year();
  // current year
  const currentYear = moment().year();
  const no_of_years = currentYear - DOB;

  useEffect(() => {
    if (no_of_years < 13) {
      privacyUUID &&
        dispatch(
          updatePrivacySetting(
            privacyUUID,
            {
              share_gosurvey_results: false,
              share_career_favourites: false,
              share_program_enrollments: false,
            },
            res => {
              if (res.privacy_uuid) {
                dispatch(fetchPrivacySetting());
                enqueueSnackbar(successMsg, {
                  variant: 'success',
                  anchorOrigin: {horizontal: 'center', vertical: 'top'},
                });
              } else {
                enqueueSnackbar(errorMsg, {
                  variant: 'error',
                  anchorOrigin: {horizontal: 'center', vertical: 'top'},
                });
              }
            },
          ),
        );
    }
  }, [no_of_years, privacyUUID]);

  const toggleDataShare = (key, checked) => {
    let toggleDataObj = {};
    toggleDataObj[key] = checked;
    privacyUUID &&
      dispatch(
        updatePrivacySetting(privacyUUID, toggleDataObj, res => {
          if (res.privacy_uuid) {
            dispatch(fetchPrivacySetting());
            enqueueSnackbar(successMsg, {
              variant: 'success',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          } else {
            enqueueSnackbar(errorMsg, {
              variant: 'error',
              anchorOrigin: {horizontal: 'center', vertical: 'top'},
            });
          }
        }),
      );
  };

  return (
    <ContentBox elevation={0} sx={{p: 4}}>
      <ErrorBoundary nameOfComponent='dashboard-data-privacy'>
        <Stack spacing={2}>
          <Typography color='text.primary' variant='h4'>
            {heading}
          </Typography>
          <Box sx={{width: {xs: '100%', md: '87%'}}}>
            <Typography color='text.secondary' variant='body2'>
              {subHeading}
            </Typography>
          </Box>
          <RequestErrorLoader
            fullWidth
            hideEmpty
            body={{
              data: privacyData,
              request: privacySaveRequest || privacyRequest || userDataRequest,
            }}>
            <Grid container>
              {dataPrivacyItems?.map(({label, key, toolTipDesc}) => (
                <Grid item mt={1} lg={3} md={3} sm={6} xs={12}>
                  <Stack direction='row' spacing={1}>
                    <Typography
                      fontWeight={500}
                      variant='subtitle2'
                      color='text.primary'>
                      {`${shareLabel} ${label}`}
                    </Typography>
                    <Tooltip
                      title={{
                        showIcon: true,
                        variant: 'title-lg-bold',
                        label: `${shareLabel} ${label}`,
                        iconSize: {height: '40px', width: '40px'},
                      }}>
                      <>
                        <Typography
                          color='text.secondary'
                          variant='text-sm-regular'>
                          {toolTipDesc}
                        </Typography>
                        <Typography
                          color='text.secondary'
                          variant='text-sm-regular'>
                          {toolTipNote}
                        </Typography>
                      </>
                    </Tooltip>
                  </Stack>
                  {privacyData && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={privacyData[key]}
                          inputProps={{'aria-label': label}}
                          disabled={
                            no_of_years > 13
                              ? false
                              : true || !under_13_years_old
                          }
                          onChange={event =>
                            toggleDataShare(key, event.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography variant='text-sm-regular' color='midGray'>
                          {shareLabel}
                        </Typography>
                      }
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </RequestErrorLoader>
        </Stack>
      </ErrorBoundary>
    </ContentBox>
  );
};

export default DataPrivacy;
