import React, {Suspense, useEffect, useState} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RequestErrorLoader} from 'core/components';
import {isTokenExpire} from 'core/utils';
import {AppRoutes} from 'config';
import {useHistory} from 'react-router-dom';

const routesBlacklist = [
  '/',
  '/GOResume',
  '/GOSurvey',
  '/GOSurvey/survey',
  '/reset-password',
  '/giveaway',
  '/terms-of-use',
  '/accessibility',
  '/privacy-policy',
  '/resume-builder',
  '/report-abuse',
];

const navigateToPath = (path, search) => {
  // Construct the full URL dynamically
  const currentDomain = window.location.origin; // e.g., 'http://example.com'
  const fullPath = `${currentDomain}${path}${search ? `${search}` : ''}`; // Append the desired path only if search exists
  window.location.href = fullPath; // Navigate and refresh
};

// ... existing code ...

const RouterMiddleware = ({children}) => {
  const history = useHistory(); // Get the history object

  useEffect(() => {
    const unblock = history.block((location, action) => {
      const {pathname} = location;
      if (routesBlacklist.includes(pathname)) {
        navigateToPath(pathname, location.search);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history]); // Add history to the dependency array

  return children;
};

const Routes = ({
  routeData = AppRoutes,
  url = '',
  defaultRedirection = '/',
}) => {
  return (
    <RouterMiddleware>
      <Suspense fallback={<RequestErrorLoader body={{request: true}} />}>
        <Switch>
          {routeData &&
            routeData.map(route => {
              return route.protected ? (
                <PrivateRoute
                  {...route}
                  path={`${url}${route.path}`}
                  defaultRedirection={defaultRedirection}
                />
              ) : (
                <NonPrivateRoute {...route} path={`${url}${route.path}`} />
              );
            })}
          <Redirect to={defaultRedirection} />
        </Switch>
      </Suspense>
    </RouterMiddleware>
  );
};

const NonPrivateRoute = ({component: Component, subModules = [], ...rest}) => (
  <Route
    {...rest}
    render={props => <Component {...props} submodules={subModules} />}
  />
);
const PrivateRoute = ({
  component: Component,
  subModules = [],
  defaultRedirection,
  ...rest
}) => {
  const authToken = useSelector(
    state => state.auth && state.auth.auth && state.auth.auth.token,
  );
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const {isExpired} = isTokenExpire(authToken);
  useEffect(() => {
    if (authToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authToken, isExpired]); // eslint-disable-next-line

  if (isAuthenticated === null) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={props => {
        return !isAuthenticated ? (
          <Redirect
            to={{
              pathname: defaultRedirection,
              state: {from: props.location},
            }}
          />
        ) : (
          <Component {...props} submodules={subModules} />
        );
      }}
    />
  );
};

export default Routes;
