import React, {useMemo} from 'react';
import {Tooltip} from 'mui-core';
import GaugeChart from 'react-gauge-chart';
import {styled, useTheme} from '@mui/material/styles';
import {Box, Badge, Typography} from '@mui/material';
import {speedometerData, noneApplicableText} from 'data/programs.json';

const MeterContainer = styled(Box)(({width, theme}) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  svg: {
    width: width,
  },
  'svg > g > g > g:nth-child(2) > path': {
    fill: `url('#arc2') !important`,
  },
  'svg > g > g > g:first-child > path': {
    fill: `url('#arc1') !important`,
  },
  'svg > g > g > g:last-child > path': {
    fill: `url('#arc3') !important`,
  },
  [theme.breakpoints.only('md')]: {
    svg: {
      width: 330,
    },
    marginTop: 36,
  },
  [theme.breakpoints.only('sm')]: {
    marginTop: 32,
    svg: {
      width: 350,
    },
  },
  [theme.breakpoints.only('xs')]: {
    svg: {
      width: 300,
    },
  },
}));

const MeterLabelContainer = styled(Box)(({theme}) => ({
  zIndex: 2,
  height: 250,
  marginTop: '-5px',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    height: 200,
  },
}));

const MeterLabel = styled(Typography)(({theme, left, right, bottom, top}) => {
  const style = {
    position: 'absolute',
    zIndex: 6,
    color:
      theme.palette.mode === 'dark' ? 'white !important' : 'black !important',
  };
  if (left) style.left = left;
  if (right) style.right = right;
  if (top) style.top = top;
  if (bottom) style.bottom = bottom;
  return style;
});

const BadgeLabel = styled(Typography)(({theme}) => ({
  color:
    theme.palette.mode === 'dark' ? 'white !important' : 'black !important',
})); // written to fix the issue with font-color

const renderSpeedometerDial = () => {
  return (
    <Box sx={{position: 'absolute'}}>
      <svg>
        <defs>
          <linearGradient id='arc1' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#357af4' />
            <stop offset='100%' stopColor='#625bf8' />
          </linearGradient>
          <linearGradient id='arc2' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#625bf8' />
            <stop offset='100%' stopColor='#8046fa' />
          </linearGradient>
          <linearGradient id='arc3' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor='#8046fa' />
            <stop offset='100%' stopColor='#ab28fe' />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

const {okLabel, goodLabel, greatLabel} = speedometerData;

const calculateGaugeValue = gaugeValue => {
  return gaugeValue > 100 ? 100 / 100 : gaugeValue / 100;
};

const Speedometer = ({
  badgeData,
  dialValue,
  size = 'large',
  width = 400,
  labelSize = 17,
  badgeLabel = '',
  tooltipWidth,
  arcWidth = 0.3,
  showGradient = false,
  tooltipObj = {
    variant: 'h4',
    label: badgeLabel,
  },
  customLabelPlacement = {
    okPlacement: 0,
    goodPlacment: 0,
    greatPlacement: 0,
  },
  showBadge = false,
  showDialValue = true,
  showMeterDial = true,
  showMeterLabel = true,
}) => {
  const theme = useTheme();
  const calculatedGaugeValue = useMemo(() => calculateGaugeValue(dialValue), [
    dialValue,
  ]);
  const topPlacement = size === 'small' ? '30' : '60';
  const {okPlacement, greatPlacement, goodPlacment} = customLabelPlacement;
  const NeedleColor = theme?.palette?.mode === 'dark' ? 'white' : '#464A4F';

  return (
    <MeterContainer width={width}>
      {renderSpeedometerDial()}
      <MeterLabelContainer>
        {showMeterLabel && (
          <>
            <MeterLabel
              variant='h5'
              left={okPlacement}
              fontSize={labelSize}
              color='text.primary'
              top={`calc(50% - ${topPlacement}px)`}>
              {okLabel}
            </MeterLabel>
            <MeterLabel
              variant='h5'
              fontSize={labelSize}
              color='text.primary'
              right={greatPlacement}
              top={`calc(50% - ${topPlacement}px)`}>
              {greatLabel}
            </MeterLabel>
            <MeterLabel
              variant='h5'
              top={goodPlacment}
              fontSize={labelSize}
              color='text.primary'>
              {goodLabel}
            </MeterLabel>
          </>
        )}

        {showMeterDial && (
          <GaugeChart
            hideText
            animate={false}
            nrOfLevels={3}
            cornerRadius={6}
            arcPadding={0.01}
            id='gauge-chart1'
            arcWidth={arcWidth}
            needleColor={NeedleColor}
            arcsLength={[0.25, 0.5, 0.25]}
            percent={calculatedGaugeValue}
            colors={[theme.palette.secondary.main, theme.palette.primary.main]}
          />
        )}

        {showDialValue && (
          <Typography color='text.primary' variant='programtitle' mt={-2}>
            {dialValue || noneApplicableText}
          </Typography>
        )}

        {showBadge && badgeLabel && (
          <Badge
            badgeContent={
              <Tooltip
                title={tooltipObj}
                maxWidth={tooltipWidth}
                showGradient={showGradient}>
                {badgeData}
              </Tooltip>
            }>
            <BadgeLabel color='text.primary' variant='body1'>
              {badgeLabel} &nbsp;&nbsp;
            </BadgeLabel>
          </Badge>
        )}

        {badgeLabel && !showBadge && (
          <BadgeLabel color='text.primary' variant='body1'>
            {badgeLabel} &nbsp;&nbsp;
          </BadgeLabel>
        )}
      </MeterLabelContainer>
    </MeterContainer>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.dialValue === nextProps.dialValue;
}
export default Speedometer;
