import {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
// hooks
import {useAuth, useInstance, useUser} from 'core/hooks';
// components
import {
  Alert,
  Stack,
  useTheme,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Button,
  DialogBox,
  StaticBanner,
  AppBreadcrumb,
  ErrorBoundary,
  LayoutContainer,
  QualifyingPrograms,
} from 'mui-core';
import {About, RelatedCareers} from './subModules';
// redux
import {
  getOccupationDetails,
  fetchOccupationDetails,
  clearOccupationDetails,
} from 'redux/modules/occupation';
import {
  getGoSurveyResultDetails,
  fetchGoSurveyResultDetails,
  goSurveyResultDetailsClear,
} from 'redux/modules/survey';
import {
  getSavedCareers,
  subscribeCareer,
  fetchSavedCareers,
  unSubscribeCareer,
  clearSavedCareerListError,
} from 'redux/modules/career-destination';
import {openLoginScreen} from 'redux/modules/auth/actions';
import {NewJobs, Apprenticeships} from 'app/modules/opportunities';
//utils
import {
  Check,
  Share,
  Favorite,
  ArrowBack,
  FavoriteBorder,
} from '@mui/icons-material';
import {useSnackbar} from 'notistack';
import {getGoBanner} from 'mui-core/utils';
import CopyToClipboard from 'react-copy-to-clipboard';
// json
import {goSurveyResults} from 'data/goSurvey.json';
import {exploreCareer} from 'data/exploreCareer.json';

const {
  jobsTab,
  tabsTitles,
  programsTab,
  shareSuccessMsg,
  apprenticeshipsTab,
  saveCareer: {
    errorMsg,
    popupTitle,
    cancelButton,
    confirmButton,
    alreadySavedMsg,
    popupDescription,
  },
} = goSurveyResults;
const tabKeys = ['', 'programs', 'jobs', 'apprenticeships', 'related-careers'];

const CareerDetails = () => {
  // hooks
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {defaultLocation} = exploreCareer;
  const {data: userData = {}} = useUser() || {};
  const urlToBeCopied = `${window.location.href}`;
  const [location, setLocation] = useState('');
  const [checkSave, setCheckSave] = useState(false);
  const [isRelatedCareer, setIsRelatedCareer] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const {career_name = '', cip_code = '', activeView = ''} = useParams();
  const {
    isCriminalJustice,
    data: {default_msa: {city = '', state_code = '', msa_code = ''} = {}} = {},
  } = useInstance();
  // selectors
  const savedCareerData = useSelector(getSavedCareers) || {};
  const {data: careerInfo = {}} = useSelector(getOccupationDetails) || {};
  const goSurveyResultDetails = useSelector(getGoSurveyResultDetails) || {};
  // destructuring
  const {student_uuid = ''} = userData || {};
  const careerName = decodeURIComponent(career_name) || '';
  const {request: goSurveyResultDetailsRequest, data = {}} =
    goSurveyResultDetails || {};
  const {data: savedCareersList = [], request: savedCareersListRequest} =
    savedCareerData || {};
  const {tagline = '', occupation_details = {}} = data?.description || {};
  const {cip4_image_cloudinary = '', occupation_onnet = ''} =
    occupation_details || {};
  const isCareerSaved =
    savedCareersList &&
    Array.isArray(savedCareersList) &&
    savedCareersList.length > 0 &&
    savedCareersList.some(
      career =>
        career?.career_name === careerName && career?.cip_code === cip_code,
    );

  const dataset = {
    data: data,
    request: goSurveyResultDetailsRequest,
  };

  // MSA location for CareerTrends
  const locationChange = location => {
    let updatedParams = isRelatedCareer ? {cip_code} : {query: careerName};
    if (typeof location === 'object') {
      updatedParams = {
        ...updatedParams,
        msa_code: location.msa_code,
        location: location.location,
      };
    } else {
      updatedParams = {...updatedParams, location: location};
    }
    setLocation(updatedParams.location);
    dispatch(fetchOccupationDetails(updatedParams));
  };

  const resetLocation = () => {
    const defLoc = city ? `${city}, ${state_code}` : defaultLocation;
    let updatedParams = isRelatedCareer ? {cip_code} : {query: careerName};
    updatedParams = {
      ...updatedParams,
      msa_code: msa_code ?? '',
      location: defLoc,
    };
    setLocation(updatedParams.location);
    dispatch(fetchOccupationDetails(updatedParams));
  };

  const tabs = useMemo(() => {
    let tabsList = [];
    tabsList.push({
      key: '',
      tabName: tabsTitles[0],
      component: (
        <About
          location={location}
          onResetLocation={resetLocation}
          onLocationChange={locationChange}
          goSurveyResultDetails={goSurveyResultDetails}
        />
      ),
    });
    {
      careerInfo?.metrics?.programs > 0 &&
        tabsList.push({
          key: tabKeys[1],
          tabName: tabsTitles[1],
          component: (
            <QualifyingPrograms
              disableBGcolor
              showSort={false}
              showSearch={false}
              cipCode={cip_code}
              showDivider={false}
              applyRouting={false}
              title={programsTab.title}
              occupationName={careerName}
              noResults={programsTab.noResults}
            />
          ),
        });
    }
    {
      careerInfo?.metrics?.jobs > 0 &&
        tabsList.push({
          key: tabKeys[2],
          tabName: tabsTitles[2],
          component: (
            <NewJobs
              tabPage={true}
              disableBGcolor
              title={jobsTab.title}
              search_query={decodeURIComponent(careerName || '')}
            />
          ),
        });
    }
    {
      !isCriminalJustice &&
        careerInfo?.metrics?.apprenticeships > 0 &&
        tabsList.push({
          key: tabKeys[3],
          tabName: tabsTitles[3],
          component: (
            <Apprenticeships
              disableBGcolor
              tabPage={true}
              cipCode={cip_code}
              onetCode={occupation_onnet}
              title={apprenticeshipsTab.title}
            />
          ),
        });
    }
    tabsList.push({
      key: tabKeys[4],
      tabName: tabsTitles[4],
      component: (
        <RelatedCareers
          disableBGcolor
          cipCode={cip_code}
          careerName={careerName}
        />
      ),
    });
    return tabsList;
  }, [data?.description, location, careerInfo?.metrics]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    careerName &&
      cip_code &&
      dispatch(fetchGoSurveyResultDetails(careerName, cip_code));
    return () => dispatch(goSurveyResultDetailsClear());
  }, [careerName, cip_code]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const defLoc = city ? `${city}, ${state_code}` : defaultLocation;
    const reqParams = {
      msa_code: msa_code ?? '',
      query: careerName ?? '',
      location: defLoc,
    };
    setLocation(defLoc);
    // We are passing this callback only when we redirect from related career click,
    // and passing cip_code instead of careerName as we got that career from chatgpt
    const callback = response => {
      if (response && Object.keys(response).length === 0) {
        const params = {
          cip_code,
          location: defLoc,
          msa_code: msa_code ?? '',
        };
        setIsRelatedCareer(true);
        dispatch(fetchOccupationDetails(params));
      }
    };
    careerName && dispatch(fetchOccupationDetails(reqParams, callback));
    return () => {
      dispatch(clearOccupationDetails());
    };
  }, [msa_code, careerName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Clear student saved careers on logout
    return () => {
      dispatch(clearSavedCareerListError());
    };
  }, [student_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(async () => {
    if (checkSave && student_uuid) {
      const userSavedCareers = await dispatch(fetchSavedCareers(student_uuid));
      const presentCareerSaved =
        userSavedCareers &&
        Array.isArray(userSavedCareers) &&
        userSavedCareers.length > 0 &&
        userSavedCareers.some(
          career =>
            career?.career_name === careerName && career?.cip_code === cip_code,
        );
      if (presentCareerSaved) {
        enqueueSnackbar(alreadySavedMsg, {
          variant: 'warning',
          children: () => {
            return (
              <Alert
                icon={<Check fontSize='inherit' />}
                sx={{width: '100%', color: 'white'}}
              />
            );
          },
        });
      } else {
        onSaveCareer();
      }
      setCheckSave(false);
    } else {
      student_uuid &&
        !savedCareersList &&
        dispatch(fetchSavedCareers(student_uuid));
    }
  }, [checkSave, student_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!tabKeys.includes(activeView)) {
      history.push(
        `/career-details/${encodeURIComponent(careerName)}/${cip_code}`,
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onTabChange = (e, tabName) => {
    let url = `/career-details/${encodeURIComponent(
      careerName,
    )}/${cip_code}/${tabName}`;
    if (!tabKeys.includes(tabName)) {
      url = `/career-details/${encodeURIComponent(careerName)}/${cip_code}/${
        tabKeys[0]
      }`;
    }
    history.push(url);
  };

  const handleCopy = () => {
    enqueueSnackbar(shareSuccessMsg, {
      variant: 'success',
      children: () => {
        return (
          <Alert
            icon={<Check fontSize='inherit' />}
            sx={{width: '100%', color: 'white'}}
          />
        );
      },
    });
  };

  const unSaveCareer = () => {
    const {saved_career_uuid: careerId = ''} = savedCareersList.find(
      career =>
        career?.career_name === careerName && career?.cip_code === cip_code,
    );

    careerId &&
      dispatch(
        unSubscribeCareer(careerId, res => {
          if (res?.Success) {
            enqueueSnackbar(res?.Success, {
              variant: 'success',
            });
            dispatch(fetchSavedCareers(student_uuid));
          } else {
            enqueueSnackbar(errorMsg, {
              variant: 'waring',
            });
          }
        }),
      );
    setConfirmationModal(false);
  };

  // eslint-disable-next-line
  const onSaveCareer = () => {
    const data = {
      cip_code: cip_code,
      career_name: careerName,
      student_uuid: student_uuid,
      cip4_image_cloudinary: cip4_image_cloudinary,
    };

    if (!isCareerSaved && student_uuid) {
      {
        dispatch(
          subscribeCareer(data, res => {
            if (res?.Success) {
              enqueueSnackbar(res?.Success, {
                variant: 'success',
                children: () => {
                  return (
                    <Alert
                      icon={<Check fontSize='inherit' />}
                      sx={{width: '100%', color: 'white'}}
                    />
                  );
                },
              });
              dispatch(fetchSavedCareers(student_uuid));
            } else {
              enqueueSnackbar(errorMsg, {
                variant: 'waring',
              });
            }
          }),
        );
      }
    } else {
      token && setConfirmationModal(true);
    }
  };

  const onSaveJobClick = () => {
    if (token) {
      onSaveCareer();
    } else {
      dispatch(
        openLoginScreen({
          callback: () => {
            setCheckSave(true);
          },
        }),
      );
    }
  };

  return (
    <ErrorBoundary nameOfComponent='career-details'>
      <Container maxWidth='xl'>
        <AppBreadcrumb
          dataList={[
            {
              name: 'Careers',
              path: '/careers',
            },
            {
              name: careerName || '',
            },
          ]}
        />
      </Container>
      <LayoutContainer
        centeredTab
        tabArr={tabs}
        disableBGcolor
        enableFullWidth
        dataSet={dataset}
        showSearch={false}
        typeOfUi='subPage'
        showDivider={false}
        showFilterBtn={false}
        activeTab={activeView}
        nameOfComponent='careers details'
        onChange={(e, index) => onTabChange(e, index)}
        banner={
          <>
            <StaticBanner
              desc={tagline}
              titleVariant='title55'
              descVariant='pagedesc'
              title={careerName || ''}
              url={getGoBanner(cip4_image_cloudinary)}
              inDemand={careerInfo?.occupation_details?.in_demand_tag || false}
            />
            <Container maxWidth='xl'>
              <Stack
                mt={1}
                mb={-2}
                columnGap={2}
                direction='row'
                justifyContent='end'>
                {/* <IconButton
                  aria-label='goBack'
                  title='Previous Page'
                  onClick={() => history.goBack()}
                  size={isMediumDevice ? 'medium' : 'large'}>
                  <ArrowBack fontSize='inherit' />
                </IconButton> */}
                <CopyToClipboard
                  id='share-button'
                  onCopy={handleCopy}
                  text={urlToBeCopied}>
                  <IconButton
                    title='Share URL'
                    aria-label='share'
                    size={isMediumDevice ? 'medium' : 'large'}>
                    <Share fontSize='inherit' />
                  </IconButton>
                </CopyToClipboard>
                <IconButton
                  title='Save'
                  aria-label='save'
                  onClick={onSaveJobClick}
                  disabled={savedCareersListRequest}
                  size={isMediumDevice ? 'medium' : 'large'}
                  color={isCareerSaved && token ? 'success' : 'black'}>
                  {isCareerSaved && token ? (
                    <Favorite fontSize='inherit' />
                  ) : (
                    <FavoriteBorder fontSize='inherit' />
                  )}
                </IconButton>
              </Stack>
            </Container>
          </>
        }
        loadingTitle='Please wait while we are fetching the career details'
      />
      <DialogBox
        openDialog={confirmationModal}
        closeDialog={() => setConfirmationModal(false)}
        actions={
          <>
            <Button
              variant='outlined'
              sx={{minWidth: {xs: '80px', md: '168px'}}}
              onClick={() => setConfirmationModal(false)}>
              {cancelButton}
            </Button>

            <Button
              variant='contained'
              onClick={unSaveCareer}
              sx={{minWidth: {xs: '80px', md: '168px'}}}>
              {confirmButton}
            </Button>
          </>
        }>
        <Typography mb={2} variant='h4' textAlign='center' color='text.primary'>
          {popupTitle}
        </Typography>
        <Typography
          component='p'
          variant='bodyL'
          textAlign='center'
          color='text.primary'>
          {popupDescription}{' '}
          <Typography
            variant='h6'
            component='span'
            color={theme.palette.primary.main}>
            {careerName}
          </Typography>
          ?
        </Typography>
      </DialogBox>
    </ErrorBoundary>
  );
};

export default CareerDetails;
