import {useState} from 'react';
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Tooltip as LightTooltip} from 'mui-core';
import {programMarketDetailsData} from 'data/programs.json';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

const BorderLinearProgress = styled(LinearProgress)(({}) => ({
  height: 14,
  borderRadius: 50,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'gba(123, 96, 223, 0.12)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 50,
    background: `linear-gradient(to right, #357AF4, #B026FE)`,
  },
}));

const HighlightedText = styled(Typography)(({theme}) => ({
  '& .highlighted-text': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.light,
  },
}));

const StyledTypography = styled(Typography)(({theme}) => ({
  color:
    theme.palette.mode === 'dark' ? 'white !important' : 'black !important',
})); // written to fix the issue with font-color

const StyledMarketToolTip = styled(({className, ...props}) => (
  <Tooltip arrow placement='top' {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    padding: '6px, 12px, 6px, 12px',
    background: `linear-gradient(to bottom, #804FF3, #601AAD)`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `#601AAD`,
  },
}));

const {
  lowRangeLabel,
  highRangeLabel,
  mediumRangeLabel,
} = programMarketDetailsData;

const LinearBarGraph = ({ProgressData = []}) => {
  const [hovers, setHovers] = useState({});

  const getRangeToText = rangeValue => {
    if (rangeValue === 0 || rangeValue === null) {
      return 0;
    } else if (rangeValue <= 25) {
      return lowRangeLabel;
    } else if (rangeValue <= 75) {
      return mediumRangeLabel;
    } else return highRangeLabel;
  };

  return (
    <Box>
      {ProgressData.map(
        ({label, progressValue, marketDescription, dataSourcetext}, idx) => (
          <Stack
            mt={3}
            key={label}
            spacing={{xs: 1, md: 2}}
            direction={{xs: 'column', md: 'row'}}
            alignItems={{xs: 'left', md: 'center'}}>
            <Stack direction='row' spacing={0.5}>
              <Typography
                width={120}
                variant='caption'
                textAlign='right'
                color='text.primary'>
                {label}
              </Typography>
              <LightTooltip
                maxWidth={520}
                title={{label: label, variant: 'h4'}}>
                <>
                  <HighlightedText
                    variant='body1'
                    dangerouslySetInnerHTML={{
                      __html: marketDescription,
                    }}
                  />
                  <Typography variant='body2'>{dataSourcetext}</Typography>
                </>
              </LightTooltip>
            </Stack>
            <Stack
              width='100%'
              direction='row'
              alignItems='center'
              spacing={{xs: 1, md: 2}}>
              <Box
                width='100%'
                position='relative'
                onMouseEnter={() => setHovers({...hovers, [idx]: true})}
                onMouseLeave={() => setHovers({...hovers, [idx]: false})}>
                {hovers[idx] && (
                  <StyledMarketToolTip title={progressValue} open={true}>
                    <Box
                      mt='-2px'
                      width={2}
                      height={2}
                      bottom='100%'
                      position='relative'
                      className='tooltip-progress'
                      left={`${progressValue > 100 ? 100 : progressValue}%`}
                    />
                  </StyledMarketToolTip>
                )}

                <BorderLinearProgress
                  variant='determinate'
                  value={progressValue > 100 ? 100 : progressValue}
                />
              </Box>
              <StyledTypography
                variant='body2'
                textAlign='left'
                color='text.primary'
                width={{
                  xs: 20,
                  sm: 70,
                  md: 60,
                }}>
                {getRangeToText(progressValue)}
              </StyledTypography>
            </Stack>
          </Stack>
        ),
      )}
    </Box>
  );
};

export default LinearBarGraph;
