import {useState} from 'react';
import List from '@mui/material/List';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {fileTypeIcon} from 'core/utils';
import ListItem from '@mui/material/ListItem';
import {Button, ErrorBoundary} from 'mui-core';
import {bytesToMegaBytes} from 'mui-core/utils';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {uploadDocData} from 'data/dashboard-settings.json';
import {uploadUserDocs, deleteUserDocs} from 'redux/modules/profile';
import {Box, FormHelperText, Stack, Typography, useTheme} from '@mui/material';

const UploadFileFormats = [
  'text/csv',
  'image/bmp',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'text/plain',
  'application/pdf',
  'application/xls',
  'application/msword', // doc
  'application/vnd.ms-excel', // xls
  'application/vnd.ms-excel.sheet.macroenabled.12', //xlsm
  'application/vnd.ms-excel.sheet.binary.macroenabled.12', //xlsb
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //xlsx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
];

const DocUploader = ({
  type,
  title,
  viewOnly,
  profileData,
  maxFileSize = 10,
  description = '',
  showSizeConsumed = true,
  label = uploadDocData.addDocBtn,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const {data, total_size_consumed, request, error} = profileData;

  const onSuccess = name => {
    enqueueSnackbar(`${name} ${uploadDocData.uploadSuccessfullyMsg}`, {
      variant: 'success',
      anchorOrigin: {horizontal: 'center', vertical: 'top'},
    });
    setIsLoading(false);
  };

  const onDeleteSuccess = () => {
    enqueueSnackbar(uploadDocData.deleteSuccessfullyMsg, {
      variant: 'success',
      anchorOrigin: {horizontal: 'center', vertical: 'top'},
    });
    setIsLoading(false);
  };

  const handleFile = async e => {
    const file = e.target.files[0];
    const FileSize = bytesToMegaBytes(file.size);
    if (
      parseFloat(FileSize) > maxFileSize ||
      parseFloat(total_size_consumed) + parseFloat(FileSize) > maxFileSize
    ) {
      enqueueSnackbar(
        uploadDocData.sizeExceedErrorMsg.replace('{maxFileSize}', maxFileSize),
        {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        },
      );
      return;
    }
    if (!UploadFileFormats.includes(file.type)) {
      enqueueSnackbar(uploadDocData.AllowedFileFormatsMsg, maxFileSize, {
        variant: 'error',
        anchorOrigin: {horizontal: 'center', vertical: 'top'},
      });
      return;
    }
    setIsLoading(true);
    await dispatch(uploadUserDocs({file, key: type}, onSuccess));
    setIsLoading(false);
  };

  const deleteDoc = async docId => {
    setIsLoading(true);
    await dispatch(deleteUserDocs(docId, onDeleteSuccess));
    setIsLoading(false);
  };

  const Float_Size_Consumed = parseFloat(total_size_consumed);
  const isTotalSizeReachMax =
    Float_Size_Consumed > Number(((85 / 100) * maxFileSize).toFixed(2));

  let totalSizeConsmed = 0;
  if (Float_Size_Consumed > 0) {
    totalSizeConsmed = Float_Size_Consumed;
    if (totalSizeConsmed > maxFileSize) {
      totalSizeConsmed = maxFileSize;
    }
  }

  return (
    <ErrorBoundary nameOfComponent={`dashboard-settings-doc-uploader-${title}`}>
      <Stack
        mb={1}
        direction='row'
        alignItems='center'
        justifyContent='space-between'>
        {title && (
          <Box>
            <Typography mr={1} variant='h4' color='text.primary'>
              {title}
            </Typography>
          </Box>
        )}
        {showSizeConsumed && (
          <Stack spacing={1} direction='row'>
            <Typography variant='body2' color='text.primary'>
              {uploadDocData.totalStorageConsumedLabel}:
            </Typography>
            <Typography
              variant='body2'
              sx={isTotalSizeReachMax ? {color: 'danger'} : {}}>
              {totalSizeConsmed}MB
            </Typography>
            <Typography variant='body2' color='text.primary'>
              {' / '}
            </Typography>
            <Typography variant='body2' color='text.primary'>
              {maxFileSize}MB
            </Typography>
          </Stack>
        )}
      </Stack>
      {description && (
        <FormHelperText>
          <Typography variant='body2'>{description}</Typography>
        </FormHelperText>
      )}
      <List>
        {data &&
          Array.isArray(data) &&
          data.length > 0 &&
          data.map(({doc_name, doc_link, doc_uuid}) => {
            const Icon = fileTypeIcon(doc_link);
            return (
              <ListItem key={doc_uuid} disablePadding>
                <ListItemAvatar>
                  <Icon
                    style={{
                      fontSize: 24,
                      color: theme.palette.mode === 'light' ? 'black' : 'white',
                    }}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography
                    component='a'
                    fontSize='12px'
                    target='_blank'
                    download='file'
                    color={
                      theme.palette.mode === 'dark'
                        ? theme.palette.secondary.constrastDark
                        : theme.palette.secondary.main
                    }
                    href={doc_link}
                    sx={{textDecoration: 'none', ml: '-24px', mr: '16px'}}>
                    {doc_name}
                  </Typography>
                  {!viewOnly && (
                    <Button color='primary' onClick={() => deleteDoc(doc_uuid)}>
                      Delete
                    </Button>
                  )}
                </ListItemText>
              </ListItem>
            );
          })}
      </List>
      {!viewOnly && (
        <Button
          component='label'
          variant='outlined'
          loading={isLoading}
          sx={{width: 220, fontSize: {xs: '11px', sm: '14px'}}}>
          {label || 'Add Document'}
          <input hidden type='file' onChange={handleFile} />
        </Button>
      )}
    </ErrorBoundary>
  );
};

export default DocUploader;
