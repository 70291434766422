import React from 'react';
import {ContentBox} from 'mui-core';
import {
  Stack,
  Button,
  styled,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import {useInstance} from 'core/hooks';
import {Launch} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {externalLinkHandler} from 'mui-core/utils';
import jobsStaticData from 'data/jobs.json';

const {
  jobsDetails: {applyButtonLabels = {}},
} = jobsStaticData || {};

const StyledTypography = styled(Typography)(({theme}) => ({
  textTransform: 'capitalize',
  color:
    theme.palette.mode === 'light'
      ? theme.palette.shadeyBlack.main
      : theme.palette.shadeyBlack.contrastText,
}));

const DescriptionContainer = styled(Typography)(({theme}) => ({
  '& ul': {
    listStyle: 'disc',
    paddingLeft: '16px',
    '& > li': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.shadeyBlack.main
          : theme.palette.shadeyBlack.contrastText,
    },
  },
}));

const HonestJobDetails = ({isMobile, jobDetails, onMobileDetailsClose}) => {
  const {
    job_url,
    company = '',
    location = '',
    job_title = '',
    description = '',
  } = jobDetails || {};
  const {isCriminalJustice} = useInstance();
  return (
    <ContentBox
      variant='outlined'
      sx={{p: 3, position: 'relative', pt: isMobile ? 5 : 3}}
      className='paper-c'>
      {isMobile && (
        <IconButton
          size='small'
          sx={{position: 'absolute', left: 21, top: 5}}
          onClick={onMobileDetailsClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
      )}
      <Stack gap={1}>
        <Stack
          alignItems='center'
          flexDirection='row'
          justifyContent='space-between'>
          <StyledTypography variant='h5'>{job_title}</StyledTypography>
        </Stack>
        {company && <StyledTypography variant='h6'>{company}</StyledTypography>}
        {location && (
          <StyledTypography variant='body1' mb={1}>
            {location}
          </StyledTypography>
        )}
        {!isCriminalJustice && (
          <Button
            variant='contained'
            sx={{width: '198px'}}
            onClick={() => externalLinkHandler(job_url)}
            endIcon={<Launch sx={{marginBottom: '2px'}} />}>
            {applyButtonLabels?.applyNow}
          </Button>
        )}
      </Stack>
      {description && <Divider sx={{margin: '16px 0px'}} />}
      <DescriptionContainer
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </ContentBox>
  );
};

export default HonestJobDetails;
