import React, {useEffect, useRef, useState} from 'react';
import MenuBar from '../../MenuBar';
import {useSnackbar} from 'notistack';
import {banner} from 'data/dashboard';
import {useDispatch} from 'react-redux';
import {Check} from '@mui/icons-material';
import DropdownMenu from '../DropdownMenu';
import {styled} from '@mui/material/styles';
import ImageCropper from 'mui-core/Cropper';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {MuiProfileUpload, useInstance} from 'core/hooks';
import {uploadDocData} from 'data/dashboard-settings.json';
import ProfilePhotoUploader from '../ProfilePhotoUploader';
import {getGoBanner, bytesToMegaBytes} from 'mui-core/utils';
import ProfileStatus from '../../../../subModules/Settings/ProfileStatus';
import {updateUserProfile, fetchPrivacySetting} from 'redux/modules/profile';
import {
  Share,
  LocationOn,
  Visibility,
  MoreVertRounded,
} from '@mui/icons-material';
import {
  goBanner,
  black_medium,
  black_tiktok,
  x_logo_black,
  black_facebook,
  black_linkedin,
  black_instagram,
} from 'assets/images';
import {
  Box,
  Menu,
  Alert,
  Stack,
  Button,
  MenuItem,
  useTheme,
  Typography,
  IconButton,
  ListItemIcon,
  useMediaQuery,
  ListItemButton,
  LinearProgress,
} from '@mui/material';

const {shareSuccessMsg, previewBtnText, shareBtnText} = banner;

const StyledBanner = styled(Box)(({userBanner, isMobile}) => ({
  backgroundImage: `url(${userBanner})`,
  width: 'auto',
  display: 'flex',
  minWidth: '175px',
  alignItems: 'start',
  whiteSpace: 'nowrap',
  justifyContent: 'end',
  backgroundSize: 'cover',
  position: 'relative',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: isMobile ? '200px' : '240px',
  paddingTop: isMobile ? '10px' : '20px',
  paddingRight: isMobile ? '20px' : '40px',
  display: 'flex',
  width: 'auto',
  height: '100%',
  aspectRatio: '5 / 1',
  margin: 'auto',
}));

const ProfileContainer = styled(Box)(() => ({
  left: '0px',
  zIndex: '2',
  display: 'flex',
  alignItems: 'start',
  position: 'relative',
  justifyContent: 'flex-start',
}));

const TagContainer = styled(Stack)(({theme}) => ({
  top: 24,
  left: 0,
  zIndex: 1,
  position: 'absolute',
}));

const Tag = styled(Alert)(({}) => ({
  padding: '4px 24px',
  borderRadius: '0 42px 42px 0',
}));

let cropFile = null;

const BannerUploader = ({
  privacyData,
  submodules = [],
  showTag = false,
  viewOnly = false,
}) => {
  const {
    open_to = '',
    social_media: shareSocialMedia,
    profile_image: shareProfileImage,
    career_headline: shareCareerHeadline,
  } = privacyData || {};
  const theme = useTheme();
  let userBanner = goBanner;
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {isCriminalJustice} = useInstance();
  const bannerUploaderInputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cropModal, setCropModal] = useState(false);
  const [menuBarWidth, setMenuBarWidth] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [progress, userData, uploadImage] = MuiProfileUpload('banner');
  const {data} = userData || {};
  const open = Boolean(anchorEl);
  const {username: profileName} = data || {};
  const [isLoader, setLoader] = useState({
    banner: false,
    profile: false,
  });
  const urlOrigin = `${window?.location?.origin}`;
  const profileUrl = `/profile/${profileName || ''}`;
  const userName = `${data && data.first_name} ${data && data.last_name}`;
  const {related_industry, career_headline} = data || {};
  const shareAvatar = viewOnly ? shareProfileImage : true;
  const shareSocialMediaHandles = viewOnly ? shareSocialMedia : true;
  const filteredSubmodules =
    submodules?.filter(
      item => !(isCriminalJustice && item?.key === 'settings'),
    ) || [];

  let employmentStatus = '';
  if (open_to === 'a job') {
    employmentStatus = 'Open to Work';
  } else if (open_to === 'an apprenticeship') {
    employmentStatus = 'Open to An Apprenticeship';
  } else if (open_to === 'an internship') {
    employmentStatus = 'Open to An Internship';
  }

  if (data && data.banner) {
    userBanner = getGoBanner(data.banner, {
      quality: 100,
      e_sharpen: 100,
      e_brightness: 'auto',
    });
  }

  let location = null;
  if (data && data?.address?.city && data?.address?.state) {
    location = data.address.city + ', ' + data.address.state;
  }

  const updateWidth = () => {
    if (menuRef?.current) {
      setMenuBarWidth(menuRef?.current?.offsetWidth);
    }
  };

  useEffect(() => {
    !viewOnly && dispatch(fetchPrivacySetting());
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [menuRef?.current?.offsetWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleImageChange = async e => {
    e.preventDefault();
    const maxFileSize = 10;
    const file = e.target.files[0];
    const FileSize = bytesToMegaBytes(file?.size);
    if (parseFloat(FileSize) > maxFileSize) {
      enqueueSnackbar(
        uploadDocData.sizeExceedErrorMsg.replace('{maxFileSize}', maxFileSize),
        {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        },
      );
    } else {
      cropFile = file;
      setCropModal(true);
      // await uploadImage(file);
    }
    bannerUploaderInputRef.current.value = '';
  };

  const deleteBanner = body => {
    if ('banner' in body) {
      setLoader({
        banner: true,
        profile: false,
      });
    } else {
      setLoader({
        banner: false,
        profile: true,
      });
    }

    dispatch(
      updateUserProfile(body, _success => {
        setLoader({
          banner: false,
          profile: false,
        });
      }),
    );
  };

  const handleCopy = () => {
    enqueueSnackbar(shareSuccessMsg, {
      variant: 'success',
      anchorOrigin: {vertical: 'top', horizontal: 'center'},
      children: () => {
        return (
          <Alert
            icon={<Check fontSize='inherit' />}
            sx={{width: '100%', color: 'white'}}
          />
        );
      },
    });
  };

  const SocialIconsButton = props => {
    const {link, src, alt} = props;
    return (
      <IconButton
        size='small'
        href={link}
        target='_blank'
        sx={{filter: `invert(${theme.palette.mode === 'dark' ? 1 : 0})`}}>
        <img
          src={src}
          alt={alt}
          style={{maxHeight: isMobile && '14px', maxWidth: isMobile && '14px'}}
        />
      </IconButton>
    );
  };

  const handleBannerMenuClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onBannerMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const renderCareerDetail = viewOnly => {
    let headlineText = '';
    if (viewOnly) {
      if (shareCareerHeadline && career_headline) {
        headlineText = career_headline;
      }
    } else {
      if (career_headline) {
        headlineText = career_headline;
      }
    }

    return (
      <>
        {headlineText && (
          <Typography pl={0.5} variant='body2' color={'text.primary'}>
            {headlineText}
          </Typography>
        )}
      </>
    );
  };

  const onCrop = async crop => {
    const {file, image} = crop;
    setCropModal(false);
    await uploadImage(file);
  };

  return (
    <>
      <input
        type='file'
        accept='image/*'
        style={{display: 'none'}}
        id='banner-uploader-input'
        aria-label='banner-uploader'
        onChange={handleImageChange}
        ref={bannerUploaderInputRef}
      />
      <Box
        ref={menuRef}
        sx={{overflow: 'hidden', position: 'relative'}}
        // height={{xs: '320px', sm: '360px'}}
      >
        <StyledBanner userBanner={userBanner} isMobile={isMobile}>
          {showTag && employmentStatus && (
            <TagContainer>
              <Tag severity='info' variant='filled'>
                <Typography variant='caption'>{employmentStatus}</Typography>
              </Tag>
            </TagContainer>
          )}
          {!viewOnly && (
            <DropdownMenu
              loader={progress}
              id='banner-editor'
              deleteImage={() => {
                deleteBanner({banner: null});
              }}
              htmlForLabel='banner-uploader-input'
            />
          )}
        </StyledBanner>
        {(progress > 0 || isLoader.banner) && (
          <LinearProgress
            sx={{position: 'absolute', width: '100%'}}
            variant={'indeterminate'}
            value={progress}
          />
        )}
        <Stack
          width='100%'
          px={isMobile ? 1 : 4}
          justifyContent='space-between'
          direction={{xs: 'column', md: 'row'}}>
          <ProfileContainer>
            {shareAvatar && (
              <ProfilePhotoUploader
                viewOnly={viewOnly}
                isMobileView={isMobile}
                deleteBanner={deleteBanner}
                isLoader={isLoader.profile}
              />
            )}
            <Stack gap={0.4} pl={1} pt={0.3}>
              {userName && (
                <Typography
                  pl={0.5}
                  variant='h5'
                  title={userName}
                  color='text.primary'
                  lineHeight={2}
                  textTransform='capitalize'>
                  {userName}
                </Typography>
              )}
              {renderCareerDetail(viewOnly)}
              {location && (
                <Stack direction='row' alignItems='center'>
                  <LocationOn
                    sx={{
                      color:
                        theme.palette.mode === 'light'
                          ? theme.palette.text.secondary
                          : theme.palette.darkGray.contrastText,
                      fontSize: isMobile ? 'small' : '20px',
                    }}
                  />
                  <Typography
                    variant='body2'
                    textTransform='capitalize'
                    color={
                      theme.palette.mode === 'light'
                        ? theme.palette.text.secondary
                        : theme.palette.darkGray.contrastText
                    }
                    sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {location}
                  </Typography>
                </Stack>
              )}
              {/* {shareSocialMediaHandles && (
                <Stack gap={0.5} direction='row'>
                  {data && data.facebook_link && data.show_facebook_link && (
                    <SocialIconsButton
                      alt='fb'
                      src={black_facebook}
                      link={data.facebook_link}
                    />
                  )}

                  {data && data.twitter_link && data.show_twitter_link && (
                    <SocialIconsButton
                      alt='tw'
                      src={x_logo_black}
                      link={data.twitter_link}
                    />
                  )}

                  {data && data.linkedin_link && data.show_linkedin_link && (
                    <SocialIconsButton
                      alt='li'
                      src={black_linkedin}
                      link={data.linkedin_link}
                    />
                  )}

                  {data && data.instagram_link && data.show_instagram_link && (
                    <SocialIconsButton
                      alt='ig'
                      src={black_instagram}
                      link={data.instagram_link}
                    />
                  )}

                  {data && data.tiktok_link && data.show_tiktok_link && (
                    <SocialIconsButton
                      alt='tt'
                      src={black_tiktok}
                      link={data.tiktok_link}
                    />
                  )}

                  {data && data.medium_link && data.show_medium_link && (
                    <SocialIconsButton
                      alt='med'
                      src={black_medium}
                      link={data.medium_link}
                    />
                  )}
                </Stack>
              )} */}
            </Stack>
          </ProfileContainer>
          {!viewOnly && (
            <Stack
              direction='row'
              alignItems='center'
              my={{xs: 1, md: 0}}
              gap={isMobile ? 0 : 2}
              justifyContent='flex-end'
              width={{xs: '100%', md: '65%'}}>
              {/* <ProfileStatus isCriminalJustice={isCriminalJustice} /> */}
              {!isCriminalJustice && (
                <Button
                  color='primary'
                  aria-haspopup='true'
                  onClick={handleBannerMenuClick}
                  aria-expanded={open ? 'true' : undefined}
                  aria-controls={open ? 'banner-menu' : undefined}
                  sx={{
                    width: '24px',
                    borderRadius: '50%',
                    padding: {xs: '4px', sm: '4px'},
                    minWidth: {xs: '20px', sm: '38px'},
                    minHeight: {xs: '20px', sm: '38px'},
                    marginRight: {xs: '16px', md: '0px'},
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    '&:focus-visible': {
                      outline: `2px solid ${theme.palette.primary.main}`,
                      outlineOffset: '4px',
                    },
                  }}>
                  <MoreVertRounded
                    sx={{
                      color: 'white',
                      width: {xs: '14px', sm: '18px', md: '20px'},
                      height: {xs: '14px', sm: '18px', md: '20px'},
                    }}
                  />
                </Button>
              )}
              <Menu
                open={open}
                id='banner-menu'
                anchorEl={anchorEl}
                onClose={onBannerMenuClose}
                onClick={onBannerMenuClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    mt: 1.5,
                    ml: '40px',
                    right: '30px',
                    width: '150px',
                    overflow: 'visible',
                    background:
                      theme.palette.mode === 'light'
                        ? theme.palette.background.paper
                        : theme.palette.dialogDarkBG.main,
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    '& .MuiAvatar-root': {
                      mr: 1,
                      ml: -0.5,
                      width: 32,
                      height: 32,
                    },
                    '& ul': {
                      padding: '0px !important',
                      '& li': {
                        height: '48px',
                        padding: '0px !important',
                        '& .MuiListItemButton-root': {
                          height: '48px',
                          color:
                            theme.palette.mode === 'light' ? 'black' : 'white',
                        },
                      },
                    },
                    '&::before': {
                      top: 0,
                      zIndex: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                    },
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>
                <MenuItem onClick={onBannerMenuClose}>
                  <ListItemButton
                    target='_blank'
                    href={profileUrl}
                    id='preview-button'>
                    <ListItemIcon>
                      <Visibility fontSize='small' />
                    </ListItemIcon>
                    {previewBtnText}
                  </ListItemButton>
                </MenuItem>
                <CopyToClipboard
                  id='share-button'
                  onCopy={handleCopy}
                  text={`${urlOrigin}${profileUrl}`}>
                  <MenuItem onClick={onBannerMenuClose}>
                    <ListItemButton>
                      <ListItemIcon>
                        <Share fontSize='small' />
                      </ListItemIcon>
                      {shareBtnText}
                    </ListItemButton>
                  </MenuItem>
                </CopyToClipboard>
              </Menu>
              {/* <Stack
                mb={{xs: 0, sm: 2}}
                gap={isMobile ? 0 : 2}
                direction={{xs: 'column', sm: 'row'}}>
                <IconButton
                  target='_blank'
                  id='share-button'
                  href={profileUrl}
                  title={previewBtnText}>
                  <Visibility
                    sx={{
                      fontSize: isMobile ? '18px !important' : '24px',
                    }}
                  />
                </IconButton>
                <CopyToClipboard
                  id='share-button'
                  onCopy={handleCopy}
                  text={`${urlOrigin}${profileUrl}`}>
                  <IconButton
                    id='share-button'
                    aria-haspopup='true'
                    title={shareBtnText}>
                    <Share
                      sx={{
                        fontSize: isMobile ? '18px !important' : '24px',
                      }}
                    />
                  </IconButton>
                </CopyToClipboard>
              </Stack> */}
            </Stack>
          )}
        </Stack>
      </Box>
      {!viewOnly && (
        <MenuBar submodules={filteredSubmodules} width={menuBarWidth} />
      )}
      <ImageCropper
        visible={cropModal}
        onCancel={() => setCropModal(false)}
        cropFile={cropFile}
        onCrop={onCrop}
      />
    </>
  );
};
export default BannerUploader;
