import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Grid, Tooltip} from 'mui-core';
import {CheckStar} from 'mui-core/Icons';
import {useTheme} from '@mui/material/styles';
import {getResumeData} from 'redux/modules/resume-builder';
import {Stack, Typography, FormControlLabel} from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

const DetailsSection = ({
  title,
  sectionInfo,
  tooltipData,
  handleStepChange,
}) => {
  const [sections, setSections] = useState([]);

  const theme = useTheme();
  const {currStep: activeStep} = useSelector(getResumeData) || {};

  useEffect(() => {
    setSections([...sectionInfo]);
  }, [sectionInfo]);

  return (
    <Stack
      p={2}
      position='relative'
      // direction={{xs: 'column', lg: 'row'}}
      // alignItems={{xs: 'flex-start', lg: 'center'}}
    >
      <Stack
        minWidth={150}
        direction='row'
        mb={{xs: 2, lg: 0}}
        sx={{
          '& .info-icon': {
            top: '8px',
            color: 'white',
            cursor: 'pointer',
            position: 'absolute',
          },
        }}>
        <Typography variant='body1' textAlign={{xs: 'start', lg: 'center'}}>
          {title}
        </Typography>
        <Tooltip showGradient title={{label: title}}>
          {tooltipData}
        </Tooltip>
      </Stack>
      <Grid container width='100%' spacing={1} pt={2}>
        {sections?.map(({key, label, value}) => (
          <Grid item xs={12} key={key}>
            <FormControlLabel
              sx={{
                gap: '12px',
                margin: '5px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => handleStepChange(key)}
              label={
                <Stack
                  justifyContent='center'
                  sx={
                    key === activeStep
                      ? {
                          pl: 1,
                          minHeight: 40,
                          minWidth: 186,
                          borderRadius: '4px',
                          backgroundColor: theme.palette.purplebg12perc.main,
                        }
                      : {}
                  }>
                  <Typography
                    variant='body2'
                    color={
                      theme.palette.mode === 'light'
                        ? 'darkGray.main'
                        : 'darkGray.contrastText'
                    }>
                    {label}
                  </Typography>
                </Stack>
              }
              control={
                value ? (
                  <CheckStar size='small' />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    sx={{
                      bgcolor: 'grey.200',
                      border: '1px solid',
                      borderColor: 'grey.200',
                      borderRadius: '4px',
                      color: 'grey.200',
                      width: 18,
                      height: 18,
                    }}
                  />
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default DetailsSection;
