//hooks
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {useAuth, useOpportunitiesData} from 'core/hooks';
//redux
import {
  fetchSavedOpportunities,
  fetchAppliedOpportunities,
} from 'redux/modules/occupation';
//component
import {
  Box,
  Paper,
  Stack,
  styled,
  useTheme,
  Pagination,
  Typography,
  paperClasses,
  useMediaQuery,
} from '@mui/material';
import {themeColorMode} from 'mui-core';
import JobCard from './components/JobCard';
import JobDetails from './components/JobDetails';
//utils
import jobsStaticData from 'data/jobs.json';
import {addMultiQueryParamsToURL, numberInUSFormat} from 'core/utils';

const {
  jobList: {title: jobLayoutTitle},
} = jobsStaticData || {};

const JobListLayout = styled(Box)(({theme}) => ({
  minHeight: 200,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '36%',
    minWidth: '36%',
  },
  [`&>.${paperClasses.root}`]: {
    minHeight: 500,
    backgroundColor: themeColorMode(
      theme,
      theme.palette.purplebg.main,
      theme.palette.background.paper,
    ),
    borderRadius: '20px',
    padding: '16px 20px',
  },
}));

const JobDetailsLayout = styled(Box)(({theme, isMobileOpen}) => ({
  flexGrow: 1,
  ' .paper-c ': {
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    ...(isMobileOpen
      ? {
          top: 0,
          left: 0,
          padding: 0,
          zIndex: 1200,
          width: '100%',
          height: '100%',
          position: 'fixed',
          margin: '0px !important',
          backgroundColor: theme.palette.background.paper,
          '&>div': {
            top: 0,
            position: 'relative',
            '& .paper-c': {
              maxHeight: 'calc(100vh - 56px)',
            },
          },
        }
      : {
          display: 'none',
        }),
  },
}));

const getJobFromQueryParamsForInitialLoad = location => {
  const search = new URLSearchParams(location.search);
  const job_id = decodeURIComponent(search.get('jobId'));
  const job_source = decodeURIComponent(search.get('source'));
  if (job_source && job_id) {
    return {job_source, job_id};
  } else return false;
};

const JobLayout = ({reqParams, onPageChange, showSearch = true}) => {
  const theme = useTheme();
  const [token] = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const [init, setInit] = useState();
  const {jobsData} = useOpportunitiesData() || {};
  const [jobSelected, setSelectedJob] = useState();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [jobDetailsOnMobile, setJobDetailsOnMobile] = useState(false);
  const {data: jobsPage = [], request: jobsRequest} = jobsData || {};
  const {data: jobs, page_details} = jobsPage || {};
  const {total_results} = page_details || {};
  const pages = Math.round(total_results / reqParams.page_size);

  useEffect(() => {
    const initialJobFromQuery = getJobFromQueryParamsForInitialLoad(location);
    if (!init && location?.search && initialJobFromQuery?.job_id) {
      onJobClick(initialJobFromQuery, true, true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (token) {
      dispatch(fetchSavedOpportunities());
      dispatch(fetchAppliedOpportunities());
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(jobs) && jobs.length > 0) {
      if (
        (init || !jobSelected) &&
        jobs[0]?.job_source !== 'honest' &&
        jobs[0].job_type !== 'apprenticeship'
      ) {
        if (page_details?.page > 1) {
          onJobClick(jobs[0], false);
        } else {
          const initialJobFromQuery = getJobFromQueryParamsForInitialLoad(
            location,
          );
          if (initialJobFromQuery?.job_id !== 'null') {
            onJobClick(initialJobFromQuery, true);
          } else {
            onJobClick(jobs[0], true);
          }
        }
      }
      if (!init) setInit(true);
    }
  }, [jobs, init]); // eslint-disable-line react-hooks/exhaustive-deps

  const onJobClick = (job, skipParamsUpdate, openOnMobileView) => {
    if (openOnMobileView && isMobile) setJobDetailsOnMobile(true);
    setSelectedJob(job);
    if (!skipParamsUpdate)
      addMultiQueryParamsToURL([
        {key: 'jobId', value: encodeURIComponent(job?.job_id)},
        {key: 'source', value: encodeURIComponent(job?.job_source)},
      ]);
  };

  const onMobileDetailsClose = () => setJobDetailsOnMobile(false);

  const emptyJobs = init && jobs && Array.isArray(jobs) && jobs.length === 0;

  return (
    !emptyJobs && (
      <Stack direction={{xs: 'column', sm: 'row'}} mt={4} pb={3} spacing={2}>
        <JobListLayout showSearch={showSearch}>
          {Array.isArray(jobs) && jobs.length > 0 && (
            <Paper variant='elevation' elevation={0}>
              <Typography variant='h5' color={'text.primary'}>
                {jobLayoutTitle}
              </Typography>
              <Typography color={'GrayText'} my={1}>
                {`Showing ${
                  total_results === 10000
                    ? '10,000+'
                    : numberInUSFormat(total_results)
                } Results`}
              </Typography>
              <Box mt={2} position={'relative'} minHeight={200}>
                {jobs.map(job => (
                  <JobCard
                    data={job}
                    key={job.job_id}
                    jobSelected={jobSelected}
                    onClickCard={data => onJobClick(data, false, true)}
                  />
                ))}
              </Box>
            </Paper>
          )}
          {!jobsRequest && pages > 0 && (
            <Stack direction='row' justifyContent='center' my={2}>
              <Pagination
                count={pages}
                shape='rounded'
                variant='outlined'
                page={reqParams?.page || 1}
                onChange={(e, page) => onPageChange(page)}
                sx={{
                  '.Mui-selected': {
                    color: 'white',
                    backgroundColor: `${theme.palette.primary.main} !important`,
                  },
                }}
              />
            </Stack>
          )}
        </JobListLayout>
        <JobDetailsLayout isMobileOpen={jobDetailsOnMobile}>
          <Box position='sticky' top='120px'>
            {jobSelected && (
              <JobDetails
                isMobile={isMobile}
                jobsRequest={jobsRequest}
                jobCardInfo={jobSelected}
                onMobileDetailsClose={onMobileDetailsClose}
                jobId={jobSelected.job_id || jobSelected.source}
              />
            )}
          </Box>
        </JobDetailsLayout>
      </Stack>
    )
  );
};

export default JobLayout;
