import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  ContentBox,
  ErrorBoundary,
  RequestErrorLoader,
  NoContentNavigator,
} from 'mui-core';
import {useUser, useAuth} from 'core/hooks';
import {themeColorMode} from 'mui-core/utils';
import {Box, Container, Stack, Grid, Typography} from '@mui/material';
import {
  getPrivacySetting,
  profileDataSelector,
  fetchPrivacySetting,
  fetchSharedProfileData,
} from 'redux/modules/profile';
import {styled} from '@mui/material/styles';
import ResumePreview from './ResumePreview';
import {getFilesAndTotalSize} from 'core/utils';
import DocUploader from '../../components/DocUploader';
import {getResumeList, fetchResumeList} from 'redux/modules/resumes';
import BannerUploader from '../../components/Banner/ProfileHeader/BannerUploader';
import NoDataPreview from 'app/modules/resume-builder/sharedComponents/Preview/NoDataPreview';
import profilePreviewData from 'data/settings-profilePreview.json';

const ProfileContainer = styled(Box)(({theme}) => ({
  background:
    theme.palette.mode === 'light'
      ? themeColorMode(
          theme,
          theme.palette.purplebg.main,
          theme.palette.darkGray.main,
        )
      : theme.palette.darkGray.main,
  marginTop: 'calc(-64px - 50px)', // as there is not app header
}));

const PreviewProfile = () => {
  const dispatch = useDispatch();
  const {userName = ''} = useParams();
  const [isAuthenticated] = useAuth();
  const {data: student_Info} = useUser();
  const {data: privacyData = {}, request: privacyRequest} =
    useSelector(getPrivacySetting) || {};
  const {data: statusOnResume = [], request: requestResume} =
    useSelector(getResumeList) || {};
  const resumeData = statusOnResume?.length > 0 ? statusOnResume[0] : {};
  const StudentProfileData = useSelector(profileDataSelector) || {};
  const {
    request: profileLoading,
    data: student_profile = {},
  } = StudentProfileData;

  const {data: portfolio_document_files = []} = getFilesAndTotalSize(
    student_profile,
    'portfolio',
  );
  const {data: cover_letter_document_files = []} = getFilesAndTotalSize(
    student_profile,
    'cover-letter',
  );
  const {data: transcript_document_files = []} = getFilesAndTotalSize(
    student_profile,
    'transcript',
  );

  useEffect(() => {
    const validUserName =
      userName && userName !== 'null' && userName !== 'undefined';
    validUserName && dispatch(fetchResumeList(userName));
    validUserName && dispatch(fetchPrivacySetting(userName));
    !student_profile &&
      validUserName &&
      dispatch(fetchSharedProfileData(userName, isAuthenticated));
  }, []);

  const documentTypes = [
    {
      title: 'Transcripts',
      type: 'transcript',
      profileData: {
        data: transcript_document_files,
      },
    },
    {
      title: 'Cover Letter',
      type: 'cover-letter',
      profileData: {
        data: cover_letter_document_files,
      },
    },
    {
      title: 'Portfolio link: ',
      type: 'portfolio',
      profileData: {
        data: portfolio_document_files,
      },
    },
  ];
  console.log('statusOnResumestatusOnResume', resumeData);
  return (
    <ErrorBoundary
      typeOfUi='subPage'
      nameOfComponent='module-settings-my-profile-preview'>
      <RequestErrorLoader
        hideBackground
        body={{
          data: student_profile && student_Info,
          request: profileLoading || requestResume || privacyRequest,
        }}
        overrideNoDataContainer={
          <NoContentNavigator
            showImg
            pathTo={'/'}
            label={`${profilePreviewData?.noContentData?.goToHomePageLabel}`}
            heading={`${profilePreviewData?.noContentData?.noContentHeading}`}
            subHeading={`${profilePreviewData?.noContentData?.noContentSubHeading} ${userName}`}
          />
        }>
        <ProfileContainer>
          <Container sx={{py: 2}}>
            <ContentBox variant='outlined' sx={{pb: 3, border: 'none'}}>
              <BannerUploader showTag viewOnly privacyData={privacyData} />
              <Grid container>
                {documentTypes.map((doc, index) => {
                  return (
                    doc?.profileData?.data?.length > 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        lg={4}
                        key={index}
                        display='flex'
                        ml={{xs: 2, md: 0}}
                        justifyContent={{xs: 'left', md: 'center'}}>
                        <Stack>
                          <Typography
                            variant='h5'
                            component='span'
                            color='text.primary'>
                            {doc?.title}
                            {doc?.type === 'portfolio' && (
                              <Typography
                                component='a'
                                variant='body1'
                                target='_blank'
                                download='file'
                                href={student_profile?.portfolio_url}
                                sx={{
                                  display: 'inline',
                                  textDecoration: 'underline',
                                }}>
                                {student_profile?.portfolio_url}
                              </Typography>
                            )}
                          </Typography>
                          <DocUploader
                            viewOnly={true}
                            type={doc?.type}
                            showSizeConsumed={false}
                            profileData={doc?.profileData}
                          />
                        </Stack>
                      </Grid>
                    )
                  );
                })}
              </Grid>
              <Stack mt={2} alignItems='center'>
                <ContentBox
                  elevation={2}
                  id='resume-preview'
                  sx={{
                    paddingX: '12px',
                    paddingTop: '32px',
                    width: {xs: '90%', sm: '70%'},
                    minHeight: resumeData?.resume_uuid ? 800 : 'auto',
                  }}>
                  {resumeData?.resume_uuid ? (
                    <ResumePreview onOpenModal={true} data={resumeData} />
                  ) : (
                    <NoDataPreview
                      disableEyeIcon
                      isShareProfile
                      heading={profilePreviewData?.noActiveResumeText}
                    />
                  )}
                </ContentBox>
              </Stack>
            </ContentBox>
          </Container>
        </ProfileContainer>
      </RequestErrorLoader>
    </ErrorBoundary>
  );
};

export default PreviewProfile;
