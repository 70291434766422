import * as TYPES from './constants';
import {API} from 'config';

import {
  savePathError,
  savePathGetError,
  recieveSavePath,
  recieveGetSavePath,
  requestSavePath,
  requestGetSavePath,
} from './actions';

import {Services} from 'core/Services';
const services = new Services();

const initialState = {request: false, data: null, error: false};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_SAVE_PATH_GET:
    case TYPES.REQUEST_SAVE_PATH_POST:
    case TYPES.REQUEST_SAVE_PATH_REMOVE:
      return {
        ...state,
        request: true,
        error: false,
      };
    case TYPES.RECIEVE_SAVE_PATH_GET:
      return {
        ...state,
        request: false,
        error: false,
        data: action.payload,
      };
    case TYPES.RECIEVE_SAVE_PATH_POST:
      return {
        ...state,
        request: false,
        error: false,
      };
    case TYPES.RECIEVE_SAVE_PATH_REMOVE:
      return {
        ...state,
        request: false,
        error: false,
      };
    case TYPES.CLEAR_SAVED_PATHS:
      return initialState;
    case TYPES.SAVE_PATH_GET_ERROR:
    case TYPES.SAVE_PATH_POST_ERROR:
    case TYPES.SAVE_PATH_REMOVE_ERROR:
      return {
        ...state,
        request: false,
        error: true,
      };
    default:
      return state;
  }
};

export const savePath = (
  uuid,
  title,
  institution_id,
  cluster_names = [],
  callback,
) => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.save_path}`;
    dispatch(requestSavePath());
    return services
      .createUpdateRecord(
        token,
        endPoint,
        {
          path_uuid: uuid,
          path_name: title,
          cluster_names: cluster_names,
          institute_uuid: institution_id,
        },
        'POST',
      )
      .then(res => {
        if (callback) callback(res);
        dispatch(recieveSavePath());
      });
  } catch (err) {
    console.log(err);
    dispatch(savePathError(err));
  }
};

export const fetchSavedPaths = callback => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
    } = getState();

    let endPoint = `${API.gps.save_path}`;
    dispatch(requestGetSavePath());
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) {
        if (callback) callback(res);
        dispatch(recieveGetSavePath(res));
      } else dispatch(savePathGetError());
    });
  } catch (err) {
    console.log(err);
    dispatch(savePathGetError(err));
  }
};

export const removeSavedPath = uuid => async (dispatch, getState) => {
  try {
    const {
      auth: {auth: {token = null} = {}},
      pathways: {savedPath},
    } = getState();
    const savedPathData =
      savedPath &&
      savedPath.data &&
      savedPath.data.filter(i => i.path_uuid === uuid);
    if (savedPathData[0]) {
      let endPoint = `${API.gps.save_path}/${savedPathData[0].save_path_uuid}`;
      dispatch(requestGetSavePath());
      return services.deleteRecord(token, endPoint).then(res => {
        if (Array.isArray(res)) dispatch(recieveGetSavePath(res));
        else dispatch(savePathGetError());
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(savePathGetError(err));
  }
};
