import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
//hooks
import {useUser} from 'core/hooks';
//component
import {Accordion, ContentBox} from 'mui-core';
import SubscribedCareers from './SubscribedCareers';
import RemoveCareerDialog from '../RemoveCareerDialog';
//redux
import {
  getSavedCareers,
  fetchSavedCareers,
  unSubscribeCareer,
} from 'redux/modules/career-destination';
//utils
import {useSnackbar} from 'notistack';
import {career} from 'data/dashboard.json';

const {careerFavorites, careerUnsubscribeData} = career;
const {accordionDesc, accordionHeader} = careerFavorites;
const {title, description, errorMsg} = careerUnsubscribeData;

const CareerFavorites = () => {
  // hooks
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {data: userDetails = {}} = useUser() || {};
  const [selectedValue, setSelectedValue] = useState(null);
  const savedCareerData = useSelector(getSavedCareers) || {};
  const [showUnsubscribeDialog, setUnsubscribeDialog] = useState(false);
  const {student_uuid = ''} = userDetails || {};

  const onUnsubscribe = data => {
    setSelectedValue(data);
    setUnsubscribeDialog(true);
  };

  const unSubscribeDialogClose = () => {
    setUnsubscribeDialog(false);
  };

  const onConfirm = occupation_uuid => {
    if (occupation_uuid) {
      dispatch(
        unSubscribeCareer(occupation_uuid, res => {
          if (res?.Success) {
            enqueueSnackbar(res?.Success, {
              variant: 'success',
            });
            student_uuid && dispatch(fetchSavedCareers(student_uuid));
          } else {
            enqueueSnackbar(errorMsg, {
              variant: 'error',
            });
          }
          unSubscribeDialogClose();
        }),
      );
    }
  };

  useEffect(() => {
    student_uuid && dispatch(fetchSavedCareers(student_uuid));
  }, [student_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentBox elevation={0}>
      <Accordion
        defaultOpen
        outlined={false}
        header={accordionHeader}
        description={accordionDesc}>
        <SubscribedCareers
          onUnsubscribe={onUnsubscribe}
          savedCareerData={savedCareerData}
        />
        {showUnsubscribeDialog && (
          <RemoveCareerDialog
            dialogTitle={title}
            dialogDesc={description}
            dialogConfirm={onConfirm}
            showDialog={showUnsubscribeDialog}
            dialogClose={unSubscribeDialogClose}
            selectedValueIndex={selectedValue.uuid}
            selectedValueLabel={selectedValue.occupation_name}
          />
        )}
      </Accordion>
    </ContentBox>
  );
};

export default CareerFavorites;
