import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {themeColorMode} from 'mui-core';
import {styled, useTheme} from '@mui/material/styles';
import {infoIcon, InfoIconwhite} from 'assets/images';
import {
  Stack,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
  tooltipClasses,
  ClickAwayListener,
} from '@mui/material';

const GradientDivider = styled(Divider)(() => ({
  borderImage:
    'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  borderImageSlice: '20%',
  borderImageWidth: '2px',
}));

const LightTooltip = styled(
  ({className, toolTipWidth, showGradient, ...props}) => (
    <Tooltip
      arrow
      {...props}
      placement='bottom'
      classes={{popper: className}}
    />
  ),
)(({showGradient, toolTipWidth, theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '1px',
    borderRadius: '8px',
    maxWidth: toolTipWidth,
    color: themeColorMode(theme, 'black', 'white'),
    boxShadow: `1px 3px 12px ${theme.palette.grayText.main}`,
    backgroundColor: themeColorMode(
      theme,
      'white',
      theme.palette.darkGray.main,
    ),
    background:
      showGradient &&
      'linear-gradient(90deg, rgba(100,183,249,1) 0%, rgba(221,100,213,1) 100%)',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

const StyledInfoRounded = styled('img')(() => ({
  width: 15,
  height: 15,
  cursor: 'pointer',
}));

export default function ({
  title: {
    sx = {},
    src = '',
    label = '',
    variant = 'h4',
    fontWeight = '',
    showIcon = false,
    iconSize = {height: '40px', width: '40px'},
  },
  InfoImg,
  children,
  maxWidth = 650,
  showDivider = true,
  showGradient = false,
}) {
  const theme = useTheme();
  const toolTipWidth = maxWidth ? maxWidth : showGradient ? 800 : 650;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const InfoIconImage = InfoImg
    ? InfoIconwhite
    : theme?.palette?.mode === 'dark'
    ? InfoIconwhite
    : infoIcon;

  const [open, setOpen] = useState(false);

  const handleIconClick = () => {
    if (isMobile) {
      setOpen(prev => !prev);
    }
  };

  const handleTooltipClose = () => {
    if (isMobile) {
      setOpen(false);
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      {/* Do not remove this <div>; it is essential for mobile click functionality. */}
      <div>
        <LightTooltip
          open={open}
          isMobile={isMobile}
          toolTipWidth={toolTipWidth}
          showGradient={showGradient}
          title={
            <Stack
              spacing={2}
              sx={{
                padding: '24px',
                borderRadius: '8px',
                background: theme.palette.mode === 'light' ? 'white' : 'black',
              }}>
              <Stack direction='row' alignItems='center' gap={2}>
                {showIcon && (
                  <StyledInfoRounded
                    sx={iconSize}
                    alt='info-icon'
                    src={src ? src : InfoIconImage}
                  />
                )}
                <Typography
                  sx={sx}
                  component='h3'
                  variant={variant}
                  color='text.primary'
                  fontWeight={fontWeight}>
                  {label}
                </Typography>
              </Stack>
              {showDivider &&
                (showGradient ? (
                  <GradientDivider variant='middle' />
                ) : (
                  <Divider variant='middle' />
                ))}
              {children}
            </Stack>
          }>
          <StyledInfoRounded
            alt='info-icon'
            src={InfoIconImage}
            className='info-icon'
            onClick={handleIconClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
}

LightTooltip.propTypes = {
  title: PropTypes.object,
  children: PropTypes.element,
  showDivider: PropTypes.bool,
  showGradient: PropTypes.bool,
  maxWidth: PropTypes.number || PropTypes.string,
};
