import React, {useEffect} from 'react';
// hooks
import {useForm} from 'react-hook-form';
import {useInstance} from 'core/hooks';
// Components
import {
  Speedometer,
  MetricsTiles,
  LinearBarGraph,
  LocationSearch as LocationSearchComponent,
} from 'mui-core';
// utils
import {createField} from 'mui-core/Form';
import {numberInUSFormat} from 'core/utils';
import {
  speedometerData,
  careerMetricsData,
  noneApplicableFullText,
  careerTrendsProgressData,
} from 'data/programs.json';
import {useTheme} from '@mui/material/styles';
import {Box, Stack, Typography} from '@mui/material';

const {
  marketDemandLabel,
  marketSupplyLabel,
  marketDemandDescription,
  marketSupplyDescription,
  marketSupplyDataSourcetext,
  marketDemandDataSourcetext,
} = careerTrendsProgressData;

const {
  jobOpeningLabel,
  medianSalaryLabel,
  jobOpeningDescription,
  medianSalaryDescription,
  jobOpeningDataSourcetext,
  medianSalaryDataSourcetext,
  // avgCostLabel,
  // avgCostDescription,
  // avgCostDataSourcetext,
} = careerMetricsData;

const {
  careerOutlooktxt,
  careerTrendsLabel,
  careerOutlookLabel,
  careerOutlookDescription,
} = speedometerData;

const CareerTrends = ({reset, data, location, onLocationChange}) => {
  const form = useForm();
  const theme = useTheme();
  const {handleSubmit, setValue, getValues} = form;
  const {data: {nearby_msa = []} = {}} = useInstance();

  useEffect(() => {
    setValue('location', location);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const nearbyMSA =
    nearby_msa.map((l, idx) => {
      return {
        ...l,
        id: idx + 1,
        nearby_msa: 'Nearby Locations',
      };
    }) || [];

  const {
    occupation_currency_details: {
      // ave_cost = 0,
      ave_wage = 0,
      min_wage = 0,
      max_wage = 0,
      career_outlook = 0,
      job_openings = 0,
      market_demand = 0,
      market_supply = 0,
    } = {},
  } = data || {};

  const medianSalary = {
    min_wage: min_wage
      ? `$${numberInUSFormat(min_wage)}`
      : noneApplicableFullText,
    ave_wage: ave_wage
      ? `$${numberInUSFormat(ave_wage)}`
      : noneApplicableFullText,
    max_wage: max_wage
      ? `$${numberInUSFormat(max_wage)}`
      : noneApplicableFullText,
  };

  const careerMetricsDetails = [
    // {
    //   label: jobOpeningLabel,
    //   description: jobOpeningDescription,
    //   valueNumber: job_openings,
    //   value: job_openings
    //     ? `${numberInUSFormat(job_openings)}`
    //     : noneApplicableText,
    //   dataSourcetext: jobOpeningDataSourcetext,
    // },
    {
      label: medianSalaryLabel,
      description: medianSalaryDescription,
      dataSourcetext: medianSalaryDataSourcetext,
      valueNumber: ave_wage,
      valueObj: medianSalary,
    },
    // {
    //       label: avgCostLabel,
    //       valueNumber: ave_cost,
    //       description: avgCostDescription,
    //       dataSourcetext: avgCostDataSourcetext,
    //       value: ave_cost ? `$${numberInUSFormat(ave_cost)}` : noneApplicableText,
    //     },
  ];

  const careerProgressData = [
    {
      label: marketDemandLabel,
      progressValue: market_demand,
      dataSourcetext: marketSupplyDataSourcetext,
      marketDescription: marketDemandDescription,
    },
    {
      label: marketSupplyLabel,
      progressValue: market_supply,
      dataSourcetext: marketDemandDataSourcetext,
      marketDescription: marketSupplyDescription,
    },
  ];

  const customLabelPlacementValues = {
    okPlacement: {
      lg: 44,
      md: 30,
      sm: 40,
      xs: 30,
    },
    goodPlacment: {
      lg: -18,
      md: -10,
      sm: -35,
      xs: -20,
    },
    greatPlacement: {
      lg: 18,
      md: 0,
      sm: 10,
      xs: 10,
    },
  };

  const careerTrendsBadgeContent = () => {
    return (
      <Stack spacing={2}>
        <Typography
          variant='body1'
          dangerouslySetInnerHTML={{
            __html: careerOutlookDescription,
          }}
        />
      </Stack>
    );
  };

  const onSubmit = data => {
    const {location} = data;
    if (location) {
      onLocationChange(data['location']);
    }
  };

  const handleLocationChange = v => {
    setValue('location', v.location);
    onLocationChange(v);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  const onClear = () => {
    // uncomment below if we resetiing to United States on clear(x) button click
    // if (getValues('location') !== '') {
    //   onLocationChange(defaultLocation);
    // }
    setValue('location', '');
  };

  const onBlur = () => {
    if (reset) {
      reset();
    }
  };

  return (
    <Stack
      mt={2}
      direction='column'
      alignItems='center'
      justifyContent='center'
      spacing={{xs: 1, sm: 1, md: 1, lg: 3}}>
      <Box width='330px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          {createField('location', {
            form,
            defaultValue: location || getValues('location'),
          })(
            <LocationSearchComponent
              calledFromForm
              onBlur={onBlur}
              onClear={onClear}
              onEnterPress={v => {}}
              LocationOptions={nearbyMSA}
              onInputChange={onInputChange}
              onLocationChange={handleLocationChange}
              placeholder='Search By City or Zip Code'
              filters={{location: location || getValues('location')}}
              backgroundColor={
                theme?.palette?.mode === 'dark'
                  ? theme.palette.darkGray.main
                  : 'white'
              }
            />,
          )}
        </form>
      </Box>
      <Box pt={{xs: 4, sm: 2, md: 0, lg: 4}}>
        <Speedometer
          showBadge={true}
          tooltipWidth={400}
          showDialValue={false}
          dialValue={career_outlook}
          badgeLabel={careerOutlookLabel}
          badgeData={careerTrendsBadgeContent()}
          customLabelPlacement={customLabelPlacementValues}
        />
      </Box>
      <Box
        mt={'-18px !important'}
        pr={{xs: 1, sm: 2, md: 3, lg: 3}}
        width={{xs: '100%', sm: '80%', md: '60%', lg: '50%'}}>
        <LinearBarGraph ProgressData={careerProgressData} />
      </Box>
      <Stack
        pt={2}
        width='100%'
        spacing={{xs: 2, md: 2}}
        justifyContent={'center'}
        direction={{sm: 'column', md: 'row'}}>
        <MetricsTiles blurData={false} metricsData={careerMetricsDetails} />
      </Stack>
    </Stack>
  );
};

export default CareerTrends;
