import {useEffect, useState} from 'react';
import {LocationSearch} from 'mui-core';
import {createField} from 'mui-core/Form';
import jobsStaticData from 'data/jobs.json';
import {Box, Stack, Select, MenuItem} from '@mui/material';

const {
  filterForm: {radiusOptions},
} = jobsStaticData || {};

const HonestJobsFilterForm = ({form, filters, nearbyMSA}) => {
  const {setValue, getValues} = form;
  const [radius, setRadius] = useState(filters?.radius || '50');

  useEffect(() => {
    if (filters?.location) {
      setRadius('50');
      setValue('location', filters.location);
    }
  }, [filters]);

  const handleLocationChange = value => {
    setValue('location', value.location);
  };

  const onInputChange = value => {
    setValue('location', value.location);
  };

  const handleRadiusChange = event => {
    setRadius(event.target.value);
    setValue('radius', event.target.value);
  };

  return (
    <Stack spacing={3}>
      {createField('location', {
        form,
        defaultValue: getValues('location') || '',
        rules: {
          required: [true, 'Location is mandatory field'],
        },
      })(
        <LocationSearch
          calledFromForm
          LocationOptions={nearbyMSA}
          onInputChange={onInputChange}
          onLocationChange={handleLocationChange}
          filters={{location: getValues('location')}}
        />,
      )}
      <Box>
        {createField('radius', {
          label: 'Radius',
          form,
        })(
          <Select
            fullWidth
            label='Within'
            value={radius}
            placeholder='Select Radius'
            onChange={handleRadiusChange}>
            {radiusOptions &&
              Array.isArray(radiusOptions) &&
              radiusOptions.length > 0 &&
              radiusOptions.map(type => (
                <MenuItem value={type.value}>{type.label}</MenuItem>
              ))}
          </Select>,
        )}
      </Box>
    </Stack>
  );
};

export default HonestJobsFilterForm;
