import React from 'react';
import PropTypes from 'prop-types';
import {numberInUSFormat} from 'core/utils';
import {styled, useTheme} from '@mui/material/styles';
import {Box, Stack, Typography} from '@mui/material';

const IconWrapper = styled(Box)(() => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '35px',
  justifyContent: 'center',
  backgroundImage: `linear-gradient(to right, #6DDCFF, #7F60F9)`,
  [`& div`]: {
    p: '8px',
    width: '38px',
    height: '38px',
    display: 'flex',
    borderRadius: '35px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
}));

const StatsWithIcon = props => {
  const theme = useTheme();
  const {data = [], titleStyles = {}, countStyles = {}} = props;
  const formatCount = count => {
    if (count === '' || count === undefined || count === null) return '0';
    if (typeof count === 'string') {
      const regex = /^\d+$/;
      if (regex.test(count)) {
        return count >= 10000
          ? '10,000+'
          : numberInUSFormat(parseInt(count) || 0);
      } else {
        return count || 0;
      }
    } else if (typeof count === 'number' && count >= 10000) {
      return '10,000+';
    } else {
      return numberInUSFormat(parseInt(count) || 0);
    }
  };

  return (
    <Stack
      container
      flexWrap='wrap'
      direction='row'
      gap={{xs: 2, md: 8}}
      sx={{flexGrow: 1, margin: '24px 0px'}}>
      {data &&
        data.map(
          ({title = '', count = 0, icon}, index) =>
            count !== 0 && (
              <Stack
                item
                key={index}
                spacing={2}
                direction='row'
                alignItems='flex-start'>
                <IconWrapper>
                  <div>{icon}</div>
                </IconWrapper>
                <Stack gap='6px' minWidth={60}>
                  <Typography
                    variant='h2'
                    color='white'
                    lineHeight={1}
                    fontWeight={100}
                    sx={{
                      ...countStyles,
                      [theme.breakpoints.down('sm')]: {
                        fontSize: '18px',
                      },
                    }}>
                    {formatCount(count)}
                  </Typography>
                  <Typography
                    variant='caption'
                    color='white'
                    sx={{
                      ...titleStyles,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      ' -webkit-line-clamp': 2,
                      '-webkit-box-orient': 'vertical',
                    }}>
                    {title}
                  </Typography>
                </Stack>
              </Stack>
            ),
        )}
    </Stack>
  );
};

StatsWithIcon.propTypes = {
  data: PropTypes.array,
};

export default React.memo(StatsWithIcon);
